import {createApp} from 'vue';
import App from './App.vue';
import router from './router';

// language 
import store from './store/store';
import i18n from './language/i18n';


// classes 
import { Classes } from '@/utils/classes/Classes.js';


// bootstrap
import "bootstrap";

// animate css 
import 'animate.css';


// datepicker 
import 'air-datepicker/air-datepicker.css';


// custom directives & filters & functions & classes
import directives from './utils/directives';
import filters from './utils/filters/globalFilters';
import functions from './utils/functions/index';


// most used components 
import Tables        from '@/components/global/Tables.vue';
import MainHeading   from '@/components/global/MainHeading.vue';
import EmptySaveBtns from '@/components/global/EmptySaveBtns.vue';
import Buttons       from '@/components/global/Buttons.vue';
import BaseInput     from '@/components/input/BaseInput.vue';
import Pagination    from '@/components/global/Pagination.vue';
// inputs 
import TextareaInput from '@/components/input/TextareaInput.vue';
import NumberInput   from '@/components/input/NumberInput.vue';
import IntegerInput  from '@/components/input/IntegerInput.vue';
import RadioInput    from '@/components/input/RadioInput.vue';
import TextInput     from '@/components/input/TextInput.vue';
import DateInput     from '@/components/input/DateInput.vue';
import SelectInput   from '@/components/input/SelectInput.vue';
import FileInput     from '@/components/input/FileInput.vue';
import CheckboxInput from '@/components/input/CheckboxInput.vue';
import SearchInput   from '@/components/input/SearchInput.vue';



import printHeaderDesign1 from '@/components/print/header/PrintHeaderDesign1.vue';
import printHeaderDesign2 from '@/components/print/header/PrintHeaderDesign2.vue';
import printHeaderDesign3 from '@/components/print/header/PrintHeaderDesign3.vue';

import printFooterDesign1 from '@/components/print/footer/PrintFooterDesign1.vue';
import printFooterDesign2 from '@/components/print/footer/PrintFooterDesign2.vue';
import printFooterDesign3 from '@/components/print/footer/PrintFooterDesign3.vue';

import printInvoiceMoneyDetailsDesign1 from '@/components/print/invoice_money/PrintInvoiceMoneyDetailsDesign1.vue';
import printInvoiceMoneyDetailsDesign2 from '@/components/print/invoice_money/PrintInvoiceMoneyDetailsDesign2.vue';
import printInvoiceMoneyDetailsDesign3 from '@/components/print/invoice_money/PrintInvoiceMoneyDetailsDesign3.vue';

import printInstallmentTableDesign1 from '@/components/print/installment/PrintInstallmentTableDesign1.vue';
import printInstallmentTableDesign2 from '@/components/print/installment/PrintInstallmentTableDesign2.vue';
import printInstallmentTableDesign3 from '@/components/print/installment/PrintInstallmentTableDesign3.vue';

import PrintInvoiceContactDetailsDesign1 from '@/components/print/invoice_contact/PrintInvoiceContactDetailsDesign1.vue';
import PrintInvoiceContactDetailsDesign2 from '@/components/print/invoice_contact/PrintInvoiceContactDetailsDesign2.vue';
import PrintInvoiceContactDetailsDesign3 from '@/components/print/invoice_contact/PrintInvoiceContactDetailsDesign3.vue';

import printHeader8cm from '@/components/print/header/PrintHeader8cm.vue';
import printFooter8cm from '@/components/print/footer/PrintFooter8cm.vue';
import printInstallmentTable8cm from '@/components/print/installment/PrintInstallmentTable8cm.vue';
import PrintInvoiceContactDetails8cm from '@/components/print/invoice_contact/PrintInvoiceContactDetails8cm.vue';



const app = createApp(App);


// Register the custom directive globally & filters & functions
for (let key in directives) app.directive(key, directives[key]);
app.config.globalProperties.$filters   = filters;
app.config.globalProperties.$functions = functions;


// since i will use [ isArb() ] function a lot so i will make it easy to use without writing each time $functions.isArb() in the template
app.config.globalProperties.$isArb = functions.isArb;


// make sure to set this synchronously immediately after loading Vue and before `new Vue`
app.config.devtools = true
app.config.performance = true


app.component('Tables', Tables);
app.component('MainHeading', MainHeading);
app.component('EmptySaveBtns', EmptySaveBtns);
app.component('Buttons', Buttons);
app.component('BaseInput', BaseInput);
app.component('Pagination', Pagination);


// input 
app.component('TextareaInput', TextareaInput);
app.component('NumberInput', NumberInput);
app.component('IntegerInput', IntegerInput);
app.component('RadioInput', RadioInput);
app.component('TextInput', TextInput);
app.component('DateInput', DateInput);
app.component('SelectInput', SelectInput);
app.component('FileInput', FileInput);
app.component('CheckboxInput', CheckboxInput);
app.component('SearchInput', SearchInput);


// print 
app.component('printHeaderDesign1', printHeaderDesign1);
app.component('printHeaderDesign2', printHeaderDesign2);
app.component('printHeaderDesign3', printHeaderDesign3);

app.component('printFooterDesign1', printFooterDesign1);
app.component('printFooterDesign2', printFooterDesign2);
app.component('printFooterDesign3', printFooterDesign3);

app.component('printInvoiceMoneyDetailsDesign1', printInvoiceMoneyDetailsDesign1);
app.component('printInvoiceMoneyDetailsDesign2', printInvoiceMoneyDetailsDesign2);
app.component('printInvoiceMoneyDetailsDesign3', printInvoiceMoneyDetailsDesign3);

app.component('printInstallmentTableDesign1', printInstallmentTableDesign1);
app.component('printInstallmentTableDesign2', printInstallmentTableDesign2);
app.component('printInstallmentTableDesign3', printInstallmentTableDesign3);

app.component('PrintInvoiceContactDetailsDesign1', PrintInvoiceContactDetailsDesign1);
app.component('PrintInvoiceContactDetailsDesign2', PrintInvoiceContactDetailsDesign2);
app.component('PrintInvoiceContactDetailsDesign3', PrintInvoiceContactDetailsDesign3);

app.component('printHeader8cm', printHeader8cm);
app.component('printFooter8cm', printFooter8cm);
app.component('printInstallmentTable8cm', printInstallmentTable8cm);
app.component('PrintInvoiceContactDetails8cm', PrintInvoiceContactDetails8cm);


// classes 
app.provide('Classes', Classes);

app.use(router);
app.use(i18n);
app.use(store);
app.mount('#app');


