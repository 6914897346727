import i18n          from "@/language/i18n";
import store         from "@/store/store";
import localeEn      from 'air-datepicker/locale/en';
import AirDatepicker from 'air-datepicker';
import Validation    from '@/validation/ValidationTrigger';
import router        from "@/router";
import {CookiesController} from '@/utils/classes/CookiesController';

const t     = i18n.global.t;
const lang  = store.getters['config/getLang'];
const CookiesClass = new CookiesController();


export default {
   // ====================== check if user is admin ======================
   isAdmin() {
      const userType = store.getters['user/getUser'].type;

      if (userType == 'admin') return true;
      else return false;
   },

   // ====================== make confirmation function return true or false ======================
   confirmedAction(msg = 'lang_you_want_to_confirm_action', icon = 'info', text = '', confirmButtonColor = '#2778c4', cancelButtonColor = '#757575', confirmBtnTxt = 'lang_yes', cancelBtnTxt = 'lang_no' ) {
      return new Promise((resolve, reject) => {
         Swal.fire({
            title: `<span class="txt-cap">${t(msg)}</span>`,
            text: t(text),
            customClass: { 
               title: 'text-center border-0',
            },
            icon,
            showCancelButton: true,
            confirmButtonColor,
            cancelButtonColor,
            confirmButtonText: `<span class="text-cap">${t(confirmBtnTxt)}</span>`,
            cancelButtonText: `<span class="txt-cap">${t(cancelBtnTxt)}</span>`
            
         }).then((result) => {
            resolve(result.isConfirmed);
         });
      });

   },

   // ====================== make empty data message ======================
   emptyData(parent = '', condition = false, msg = 'lang_no_data') {
      if (document.querySelector(parent + ' #empty_data_msg')) {
         document.querySelector(parent + ' #empty_data_msg').remove();
      }

      if (condition) {
         let msgTag = document.createElement('h3');
         msgTag.setAttribute('id', 'empty_data_msg');
         msgTag.innerText = t(msg);
   
         if (document.querySelector(parent)) document.querySelector(parent).appendChild(msgTag);
      }
   },

   // ====================== check language ======================
   isArb() {
      if (CookiesClass.getCookie('vue_diamond_pos_lang') == 'en') return false;
      else return true;
   },

   // ====================== To Print Page In Current Page Or New Tab ======================
   showPrint(url) {
      if (store.getters['settings/getSettings'].newWindowPrint == ACTIVE_IN_DB) openInNewTab(url);
      else router.push(url);
   },

   
   // ====================== Get Common Used Currency To Set The Currency Of Reports  ======================
   commonCurrency(elseBoth = false) {
      if (store.getters['settings/getSettings'].salesCurrencyType == 'dollar') return 'dollar';
      else if (store.getters['settings/getSettings'].salesCurrencyType == 'dinar') return 'dinar';
      else {
         if (elseBoth) return 'both';
         else return 'dinar';
      }
   },


   // ====================== Checking For Permissions If Auth Then Show Page If Not Then Redirect To Home Page======================
   checkUserPermission(permissionName) {
      if ((store.getters['permissions/getPermissions'][permissionName] ?? 'f') == 'f') router.replace('/');
   },


   // ====================== Getting The Html Of Edit Some Page Names & Insert There Values ======================
   getEditHTML(pageName, data) {
      const settings = store.getters['settings/getSettings'];

      const isCarton     = settings.productsHandleType == 'carton' ? true : false;
      const isDollar     = settings.handleProductDinarOrDollarOrBoth == 'dollar' ? true : false;
      const isDinar      = settings.handleProductDinarOrDollarOrBoth == 'dinar' ? true : false;
      const dollarOrBoth = settings.handleProductDinarOrDollarOrBoth == 'dollar' || settings.handleProductDinarOrDollarOrBoth == 'both' ? true : false;
      const dinarOrBoth  = settings.handleProductDinarOrDollarOrBoth == 'dinar' || settings.handleProductDinarOrDollarOrBoth == 'both' ? true : false;
      const bothCurrency = settings.handleProductDinarOrDollarOrBoth == 'both' ? true : false;

      let html                   = ``;
      let inputClass             = 'form-control';
      let inputSelectClass       = 'form-select';
      let inputCheckClass        = 'form-check-input';
      let inputWrapperClass      = 'input-wrapper';
      let inputCheckWrapperClass = 'form-check';
      let inputCheckLabelClass   = 'form-check-label';
      let labelClass             = 'form-label';

      if (pageName == 'expenses') {
         let expense       = data.expense;
         let expensesTypes = data.expenses_types;
         
         let expensesTypesSelectOptions = ``;
         expensesTypes.forEach(ex => expensesTypesSelectOptions += `<option value="${ex.ETId}" ${expense.EETId == ex.ETId ? 'selected' : ''}>${ex.ETName}</option>`);
         
         html +=`
            <div id="edit_expense_html" class="edit_expense_html">
               <input type="hidden" name="currencyEdit" value="${expense.ECurrencyType}"/>
               <input type="hidden" name="oldAmountEdit" value="${expense.EAmount}"/>
               <div class="${inputWrapperClass}">
                  <label for="amountEdit" class="${labelClass}">${t('lang_amount')}</label>
                  <input type="text" name="amountEdit" id="amountEdit" class="${inputClass}" value="${expense.EAmount}" data-float-int/>
               </div>
               <div class="${inputWrapperClass}">
                  <label for="noteEdit" class="${labelClass}">${t('lang_notes')}</label>
                  <textarea type="text" name="noteEdit" id="noteEdit" class="${inputClass}">${expense.ENote}</textarea>
               </div>
               <div class="${inputWrapperClass}">
                  <label for="expenseTypeEdit" class="${labelClass}">${t('lang_expense_type')}</label>
                  <select name="expenseTypeEdit" id="expenseTypeEdit" value="${expense.EETId}" class="${inputSelectClass}">
                     ${expensesTypesSelectOptions}
                  </select>
               </div>
            </div>
         `;
      }


      if (pageName == 'suppliers_payment') {
         let supplierPayment = data;

         html +=`
         <div id="suppliers_payment_html" class="suppliers_payment_html">
            <input type="hidden" name="currencyEdit" value="${expense.ECurrencyType}"/>
            <input type="hidden" name="oldAmountEdit" value="${expense.EAmount}"/>
            <div class="${inputWrapperClass}">
               <label for="amountEdit" class="${labelClass}">${t('lang_amount')}</label>
               <input type="text" name="amountEdit" id="amountEdit" class="${inputClass}" value="${expense.EAmount}" data-float-int/>
            </div>
            <div class="${inputWrapperClass}">
               <label for="noteEdit" class="${labelClass}">${t('lang_notes')}</label>
               <textarea type="text" name="noteEdit" id="noteEdit" class="${inputClass}">${expense.ENote}</textarea>
            </div>
            <div class="${inputWrapperClass}">
               <label for="expenseTypeEdit" class="${labelClass}">${t('lang_expense_type')}</label>
               <select name="expenseTypeEdit" id="expenseTypeEdit" value="${expense.EETId}" class="${inputSelectClass}">
                  ${expensesTypesSelectOptions}
               </select>
            </div>
         </div>
      `;
      }


      return html;
   },

}
