<template>
   <div class="print_installment_table_8cm" id="print_installment_table_8cm">
      <table v-table class="table table-white border-dark">
         <thead>
            <tr>
               <th>{{ $t('lang_early_payment') }}</th>
               <th>{{ $t('lang_amount_monthly_payment') }}</th>
               <th>{{ $t('lang_remain') }}</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td>{{ $filters.setCurrency(installment_head.prepaid, currency) }}</td>
               <td>{{ $filters.setCurrency(installment_head.monthly_amount_payment, currency) }}</td>
               <td>{{ $filters.setCurrency(remain, currency) }}</td>
            </tr>
         </tbody>
      </table>
      <table v-table class="table table-white border-dark">
         <thead>
            <tr>
               <th>#</th>
               <th>{{ $t('lang_payments_date') }}</th>
               <th>{{ $t('lang_should_pay_amount') }}</th>
            </tr>
         </thead>
         <tbody>
            <tr v-for="(ib , i) in installment_body" :key="ib.id">
               <td>{{ i + 1 }}</td>
               <td>{{ ib.date_should_pay }}</td>
               <td>{{ $filters.setCurrency(ib.amount_should_pay, currency) }}</td>
            </tr>
         </tbody>
      </table>
      <table v-table class="table table-white border-dark">
         <thead>
            <tr>
               <th>#</th>
               <th>{{ $t('lang_pay_date') }}</th>
               <th>{{ $t('lang_paid') }}</th>
            </tr>
         </thead>
         <tbody>
            <tr v-for="(ib , i) in installment_body" :key="ib.id">
               <template v-if="ib.amount_paid == 0">
                  <td>{{ i + 1 }}</td>
                  <td></td>
                  <td></td>
               </template>
               <template v-else>
                  <td>{{ i + 1 }}</td>
                  <td>{{ ib.paid_date }}</td>
                  <td>{{ $filters.setCurrency(ib.amount_paid, currency) }}</td>
               </template>
            </tr>
         </tbody>
      </table>
   </div>
</template>

<script>
export default {
   props: {
      currency: String,
      installment_head: {type: [Object, Boolean],default: false},
      installment_body: {type: [Object, Boolean],default: false},
      has_discount:     {type: Boolean,default: false},
      discount:         {type: [String, Number],default: 0},
      total_invoice:    {type: [String, Number],default: 0},
      remain:           {type: [String, Number],default: 0},
   },
}
</script>

<style scoped lang="scss">
@use '@sass/utils' as *;

#print_installment_table_8cm {
   table {
      td,th {
         font-size: rem(13);
      }
   }
}
</style>