<template>
   <div class="container">
      <div class="sidebar" id="sidebar">
         <button class="expand">
            <div class="burger">
               <span></span>
               <span></span>
               <span></span>
            </div>
         </button>
         <div class="nav-holder">
            <nav class="nav">
               <ul>
                  
                  <template v-for="page in pages" :key="page.path">
                     <div v-if="page.title" class="list-title">
                        <span>{{ page.title }}</span>
                     </div>

                     <li v-else class="nav__item">
                        <router-link 
                           v-if="!page.isContainer && ($store.getters['permissions/getPermissions'][page.permissionName] ?? ACTIVE_IN_DB) === ACTIVE_IN_DB" 
                           :to="page.path" class="nav__link" 
                           :class="path == page.path ? 'active' : ''" 

                           tooltip-overflow 
                           :tt-text="$t(page.name)" 
                           :tt-dir="$isArb() ? 'left' : 'right'"
                        >
                           <p class="nav__link_text">
                              <span>{{$t(page.name)}}</span>
                           </p>
                           <span v-html="page.icon" class="sidebar-items-icon"></span>
                        </router-link>
   
                        <a 
                           v-if="page.isContainer" 
                           :to="page.path" 
                           :href="`#${page.name}`" 
                           :data-collapse-active="page.name"
                           :class="(page.childrenPaths.find(path => path === $route.path)) ? 'active current_page' : ''" 
                           data-bs-toggle="collapse" 
                           class="nav__link collapse_trigger" 
                           tooltip-overflow
                           :tt-text="$t(page.name)" 
                           :tt-dir="$isArb() ? 'left' : 'right'"
                        >
                           <i class="fa-solid fa-caret-down dropdown-icon"></i>
                           <p class="nav__link_text">
                              <span>{{$t(page.name)}}</span>
                           </p>
                           <span v-html="page.icon" class="sidebar-items-icon"></span>
                        </a>
                        
                        <div v-if="page.isContainer" class="collapse" :id="page.name">
                           <ul class="collapsed_ul">
                              <li v-for="child in page.children" class="collapse__item">
                                 <router-link v-if="(($store.getters['permissions/getPermissions'][child.permissionName] ?? ACTIVE_IN_DB) === ACTIVE_IN_DB)"  :to="child.path" class="nav__link_collapse" :class="path == child.path ? 'active' : ''" tooltip-overflow :tt-text="$t(child.name)" :tt-dir="$isArb() ? 'left' : 'right'">
                                    <p class="nav__link_collapse_text">
                                       <span>{{$t(child.name)}}</span>
                                    </p>
                                    <span v-html="child.icon" class="collapsed_ul__icons"></span>
                                 </router-link>
                              </li>
                           </ul>
                        </div>
                     </li>
                  </template>
                  
                  <button @click="logout" 
                     class="nav__link" 
                     tooltip-overflow 
                     :tt-text="'lang_logout'"
                     :tt-dir="$isArb() ? 'left' : 'right'"
                  >
                     <p class="nav__link_text">{{$t('lang_logout')}}</p>
                     <span v-html="`<i class='fa-solid fa-right-from-bracket'></i>`" class="sidebar-items-icon"></span>
                  </button>
               </ul>
            </nav>
         </div>
      </div>
   </div>
</template>

<script>
import i18n        from '@/language/i18n';
import functions   from '@/utils/functions';
import store       from '@/store/store';
import router      from '@/router';
import { useRoute } from 'vue-router';

import {ref, onMounted, watch, inject, reactive, toRefs, computed} from 'vue';

export default {
   name: 'sidebar',
   setup() {
      const state = reactive({
         ACTIVE_IN_DB,
         NOT_ACTIVE_IN_DB,
      });

      const route = useRoute();

      const Classes           = inject('Classes');
      const CRUDManager       = Classes.getClass('CRUDManager');
      const CookiesController = Classes.getClass('CookiesController');
      const DOMModifier       = Classes.getClass('DOMModifier');

      let path    = ref('');
      path.value  = '/' + route.fullPath.split('/')[1];

      router.beforeEach((to, from, next)=> {
         path.value = '/' + to.fullPath.split('/')[1];
         next();
      })

      let products = {
         isContainer: true,
         name: 'lang_items',
         icon: '<i class="fa-solid fa-boxes-packing"></i>',
         
         children: [
            {
               path: '/categories',
               name: 'lang_categories',
               icon: `<i class="fa-solid fa-list"></i>`,
               permissionName: 'categories',
            },
            {
               path: '/stores',
               name: 'lang_stores',
               icon: `<i class="fa-solid fa-warehouse"></i>`,
               permissionName: 'stores',
            },
            {
               path: '/add_products',
               name: 'lang_add_item',
               icon: `<i class="fa-solid fa-plus"></i>`,
               permissionName: 'modifyProducts',
            },
            {
               path: '/products',
               name: 'lang_show_items',
               icon: `<i class="fa-solid fa-boxes-stacked"></i>`,
               permissionName: 'products',
            },
            {
               path: '/transfer_products',
               name: 'lang_transfer_products_between_stores',
               icon: `<i class="fa-solid fa-dolly"></i>`,
               permissionName: 'transfer_products',
            },
            {
               path: '/transfer_products_reports',
               name: 'lang_transfer_products_reports',
               icon: `<i class="fa-solid fa-file-lines"></i>`,
               permissionName: 'transfer_products_reports',
            },
            {
               path: '/store_reports',
               name: 'lang_store_reports',
               icon: `<i class="fa-solid fa-file-circle-check">`,
               permissionName: 'store_reports',
            },
         ]
      }

      let users = {
         isContainer: true,
         name: 'lang_user',
         icon: '<i class="fa-solid fa-user-large"></i>',
         
         children: [
            {
               path: '/users',
               name: 'lang_users',
               icon: `<i class="fa-solid fa-user-plus"></i>`,
               permissionName: 'users',
            },
            {
               path: '/branches',
               name: 'lang_branches',
               icon: `<i class="fa-solid fa-code-branch"></i>`,
               permissionName: 'branches',
            },
            {
               path: '/users_permissions',
               name: 'lang_users_permissions',
               icon: `<i class="fa-solid fa-user-lock"></i>`,
               permissionName: 'userPermissions',
            },
            {
               path: '/settings',
               name: 'lang_program_settings',
               icon: `<i class="fa-solid fa-gear"></i>`,
               permissionName: 'settings',
            },
            {
               path: '/shop_data',
               name: 'lang_add_shop_data',
               icon: `<i class="fa-solid fa-shop"></i>`,
               permissionName: 'shopData',
            },
         ]
      }

      const stocks = {
         isContainer: true,
         name: 'lang_stock',
         icon: '<i class="fa-solid fa-money-bill-trend-up"></i>',
         
         children: [
            {
               path: '/stocks',
               name: 'lang_stocks',
               icon: `<i class="fa-solid fa-vault"></i>`,
               permissionName: 'banks',
            },
            {
               path: '/branches_profit_percents',
               name: 'lang_branches_profit_percents',
               icon: `<i class="fa-solid fa-percent"></i>`,
               // permissionName: 'banks',
            },
            {
               path: '/deposit_to_safe',
               name: 'lang_deposit_money_to_bank',
               icon: `<i class="fa-solid fa-down-long"></i>`,
               permissionName: 'banks',
            },
            {
               path: '/withdraw_from_safe',
               name: 'lang_take_money_from_bank',
               icon: `<i class="fa-solid fa-up-long"></i>`,
               permissionName: 'banks',
            },
            {
               path: '/transfer_between_safes',
               name: 'lang_transfer_money_between_banks',
               icon: `<i class="fa-solid fa-money-bill-transfer"></i>`,
               permissionName: 'banks',
            },
            {
               path: '/safe_balance',
               name: 'lang_balance_now',
               icon: `<i class="fa-solid fa-sack-dollar"></i>`,
               permissionName: 'banks',
            },
            {
               path: '/safe_records_reports',
               name: 'lang_bank_reports',
               icon: `<i class="fa-solid fa-file-invoice"></i>`,
               permissionName: 'banks',
            },
            {
               path: '/safe_profits_reports',
               name: 'lang_profits',
               icon: `<i class="fa-solid fa-file-invoice-dollar"></i>`,
               permissionName: 'banks',
            },
         ]
      },
      suppliers = {
         isContainer: true,
         name: 'lang_supplier',
         icon: '<i class="fa-solid fa-parachute-box"></i>',
         
         children: [
            {
               path: '/suppliers',
               name: 'lang_suppliers',
               icon: `<i class="fa-solid fa-people-carry-box"></i>`,
               permissionName: 'suppliers',
            },
         ]
      },
      customers = {
         isContainer: true,
         name: 'lang_customers',
         icon: '<i class="fa-solid fa-user-tie"></i>',
         
         children: [
            {
               path: '/customers',
               name: 'lang_customers_data',
               icon: `<i class="fa-solid fa-user-plus"></i>`,
               permissionName: 'customers',
            },
            {
               path: '/sponsors',
               name: 'lang_sponsors',
               icon: `<i class="fa-solid fa-user-shield"></i>`,
               permissionName: 'sponsors',
            },
            {
               path: '/customer_outer_debt',
               name: 'lang_outer_debt',
               icon: `<i class="fa-regular fa-newspaper"></i>`,
               permissionName: 'customers',
            },
         ]
      },
      invoices = {
         isContainer: true,
         name: 'lang_invoices',
         icon: '<i class="fa-solid fa-file-invoice-dollar"></i>',
         
         children: [
            {
               path: '/add_sales_invoice',
               name: 'lang_sales_invoice',
               icon: `<i class="fa-solid fa-plus"></i>`,
               permissionName: '',
            },
            {
               path: '/sales_invoice_reports',
               name: 'lang_sales_invoice_reports',
               icon: `<i class="fa-solid fa-scroll"></i>`,
               permissionName: '',
            },
         ]
      },
      expenses = {
         isContainer: true,
         name: 'lang_expenses',
         icon: '<i class="fa-solid fa-fire"></i>',

         children: [
            {
               path: '/expenses_types',
               name: 'lang_expenses_types',
               icon: `<i class="fa-solid fa-list"></i>`,
               permissionName: 'expense_types',
            },
            {
               path: '/expenses',
               name: 'lang_add_expenses',
               icon: `<i class="fa-solid fa-file-circle-plus"></i>`,
               permissionName: 'expenses',
            },
            {
               path: '/expenses_reports',
               name: 'lang_expenses_reports',
               icon: `<i class="fa-solid fa-calculator"></i>`,
               permissionName: 'expenses',
            },
         ]
      }


      // {title: 'مشتريات'},
      let sidebarPages = [
         {
            path: '/dashboard',
            name: 'lang_dashboard',
            icon: `<i class="fa-solid fa-gauge"></i>`,
         },
         {
            path: '/',
            name: 'lang_home',
            icon: `<i class="fa-solid fa-house"></i>`,
         },

         invoices,
         users,
         products,
         stocks,
         suppliers,
         customers,
         expenses,

         {
            path: '/language',
            name: 'lang_change_language',
            icon: `<i class="fa-solid fa-globe"></i>`,
         },
      ];

      const pages = computed(()=> {
         sidebarPages.forEach((page, index) => {

            if ('isContainer' in page) {
               sidebarPages[index].childrenPaths = [];
               page.children.forEach(child => sidebarPages[index].childrenPaths.push(child.path));

               return
            }
            
            sidebarPages[index].isContainer = false;
         });

         return sidebarPages;
      });


      // when click to logout btn
      function logout() {
         functions.confirmedAction().then(async resp => {
            if (resp) {
               let {[RESPONSE_BOOLEAN_NAME]: isLogout} = await CRUDManager.get('logout');
               if (!isLogout) return;
               
               CookiesController.setCookie(JWT_COOKIE_NAME, '', -1, 'second');

               await store.dispatch('user/changAuth', false);
               router.push('/login');
            }
         });
      }
      
      // sidebar actions 
      onMounted (() => {
         if (!document.querySelector('.sidebar')) return;
         
         const body              = document.body;
         const sidebar           = document.querySelector('.sidebar');
         const sidebarExpandBtn  = document.querySelector('.sidebar .expand');
         const navLinks          = document.querySelectorAll('#sidebar .nav__link, #sidebar .nav__link_collapse');
         const collapseTriggers  = document.querySelectorAll('#sidebar .collapse_trigger');

         let staticSidebarWidthWhenExpand = 0;
         const expandedValue = 4.5; // this number will be width of sidebar .. it takes the width and multiply it to this number


         // close sidebar after clicking on link in mobile 
         navLinks.forEach(link => {
            if (link.classList.contains('collapse_trigger')) return;

            link.addEventListener('click', () => {
               if (window.innerWidth > 768) return;
               sidebarExpandBtn.click();
            });
            
            if (!link.classList.contains('nav__link_collapse')) return;
            
            link.addEventListener('mouseover', () => {
               const navItemParent = DOMModifier.findParent({element: link, parentSelector: '.nav__item', returnParent: true});
               if (navItemParent.querySelector('.nav__link').classList.contains('active')) return;
               
               navItemParent.querySelector('.nav__link').classList.add('active');
            });

            link.addEventListener('mouseleave', () => {
               const navItemParent = DOMModifier.findParent({element: link, parentSelector: '.nav__item', returnParent: true});

               if (navItemParent.querySelector('.nav__link').classList.contains('current_page')) return;

               navItemParent.querySelector('.nav__link').classList.remove('active');
            });
         });

         // close other collapses when opening new one
         collapseTriggers.forEach(el => {
            el.addEventListener('click', _=> {

               let currentElement  = el.getAttribute('data-collapse-active');
               let openedCollapse  = document.querySelectorAll('#sidebar .collapse.show');

               openedCollapse.forEach(e => {
                  let id = e.getAttribute('id');
                  if (id == currentElement) return;
                  
                  let element = document.querySelector(`#sidebar [data-collapse-active='${id}']`);
                  element.click();
               })
            });
         });

         //=== insert padding for body width sidebar width
         function onlyBodyPadding(dir) {
            let sidebarWidth = sidebar.getBoundingClientRect().width;
            if (window.innerWidth < 768) {
               body.style.paddingLeft = 0;
               body.style.paddingLeft = 0;
            } else if (dir == 'right') {
               body.style.paddingRight = sidebarWidth + 'px';
               body.style.paddingLeft  = 0;
            } else {
               body.style.paddingLeft  = sidebarWidth + 'px';
               body.style.paddingRight = 0;
            }
         }

         function bodyPadding(dir) {
            let sidebarWidth = sidebar.getBoundingClientRect().width;
            if (window.innerWidth < 768) {
               body.style.paddingLeft = 0;
               body.style.paddingLeft = 0;
            } 
            else if (dir == 'right') {
               body.style.paddingRight = sidebarWidth + 'px';
               body.style.paddingLeft  = 0;
               window.addEventListener('resize', ()=> body.style.paddingRight = sidebarWidth + 'px');
            } else {
               body.style.paddingLeft  = sidebarWidth + 'px';
               body.style.paddingRight = 0;
               window.addEventListener('resize', ()=> body.style.paddingLeft = sidebarWidth + 'px');
            }
         }

         if (store.getters['config/getLang'] == 'en') bodyPadding('left'); 
         else bodyPadding('right');

         function expandSidebar(isWindowResize = false) {
            let sidebarWidthWhenExpand = sidebar.getBoundingClientRect().width * expandedValue;
            
            sidebar.classList.remove('off-transition');
            
            if (sidebar.classList.contains('expanded')) {

               navLinks.forEach(link => link.removeAttribute('tooltip-overflow'));
               
               if (window.innerWidth >= 768) {
                  if (!isWindowResize) {
                     sidebar.style.width = sidebarWidthWhenExpand + 'px';
                     
                     if (store.getters['config/getLang'] == 'en') body.style.paddingLeft =  sidebarWidthWhenExpand + 'px';
                     else body.style.paddingRight =  sidebarWidthWhenExpand + 'px';
                  } else {
                     if (sidebar.style.width == '100%') sidebar.style.width = staticSidebarWidthWhenExpand + 'px';
                     
                     if (store.getters['config/getLang'] == 'en') body.style.paddingLeft =  staticSidebarWidthWhenExpand + 'px';
                     else body.style.paddingRight =  staticSidebarWidthWhenExpand + 'px';
                  }
                  
               } else if (window.innerWidth < 450) {
                  sidebar.style.width =  '100%';
                  
                  if (store.getters['config/getLang'] == 'en') body.style.paddingLeft = 0;
                  else body.style.paddingRight = 0;
               
               } else {
                  if (!isWindowResize) sidebar.style.width =  sidebarWidthWhenExpand + 'px';
                  else sidebar.style.width = staticSidebarWidthWhenExpand + 'px';
                  
                  if (store.getters['config/getLang'] == 'en') body.style.paddingLeft = 0;
                  else body.style.paddingRight = 0;
               }
               
            } else {
               navLinks.forEach(link => link.setAttribute('tooltip-overflow', ''));
               if (window.innerWidth >= 768) {
                  if (store.getters['config/getLang'] == 'en') body.style.paddingLeft =  'var(--sidebar-width)';
                  else body.style.paddingRight =  'var(--sidebar-width)';
                  
               } else {
                  body.style.paddingRight = 0;
                  body.style.paddingLeft = 0;
               }
               
               sidebar.style.width ='var(--sidebar-width)';
            }
         }
         
         expandSidebar();
         //=== when clicking on expand sidebar btn 
         sidebarExpandBtn.addEventListener('click', function () {
            body.style.transition = '.5s ease';
            sidebar.classList.toggle('expanded');

            if (sidebar.classList.contains('expanded') && sidebar.style.width != '100%') {
               staticSidebarWidthWhenExpand = sidebar.getBoundingClientRect().width * expandedValue;
            }

            expandSidebar();
         })
         
         window.addEventListener('resize', ()=> {
            expandSidebar(true);
         });

         //=== watch changing language we change body padding
         watch(()=> store.state.config.language, (newVal)=> {
            sidebar.classList.add('off-transition');
            if (newVal == 'en') onlyBodyPadding('left'); 
            else onlyBodyPadding('right');
         });

         //=== on clicking to page not sidebar if sidebar open make it close only through 767px (mobile & tablet) of window width
         document.addEventListener('click', function(e) {
            let el         = e.target;
            let element    = null;
            let isSidebar  = false;

            if (window.innerWidth >= 768) return;

            // Traverse up the DOM tree until there are no more parent nodes
            while (el.parentNode) {
               element = el;
               el      = el.parentNode;

               if (element.getAttribute('id') == 'sidebar' || e.target.getAttribute('id') == 'sidebar') {
                  isSidebar = true;
               }
            }

            if (!isSidebar) {
               if (sidebar.classList.contains('expanded')) sidebarExpandBtn.click();
            }
         });
      });

      return {
         ...toRefs(state),

         pages,
         path,
         NOT_ACTIVE_IN_DB,
         ACTIVE_IN_DB,

         logout,
      }
   },
}

</script>