import i18n from '@/language/i18n';

export default class Validation {
   #objects   = [];
   errorMsgs  = [];

   #element      = '';
   #elementValue = '';
   #nextElement  = '';
   
   #classObject       = this;
   #errMsgNextParent  = false; 
   #elementExists     = true;

   #inputErrClassName = INPUT_ERROR_CLASS;
   #errMsgClassName   = INPUT_ERROR_MSG_REVELER_CLASS;

   addRule(object) {
      this.#objects.push(object);
      return this;
   }

   validate(name) {
      this.#setProps(name);

      if (!this.#elementExists) {
         this.#reset();
         return;
      } 

      // loop on validation classes
      for (let obj of this.#objects) {

         // check each classes if they are valid .. if not then sent err and break loop
         if (obj.validate(this.#elementValue) !== true) {
            this.#setErr(name, obj); break;
            
         } else this.#setValid();

      }

      this.#reset();
   }

   #setProps(name) {
      this.#element = document.querySelector(`[name="${name}"]`);

      if (!this.#element) 
         return this.#elementExists = false;
      else 
         this.#elementExists = true;
      

      this.#nextElement   = this.#errMsgNextParent ? (this.#element.parentElement.nextElementSibling || false) : (this.#element.nextElementSibling || false);
      this.#elementValue  = this.#element.value.trim();
      this.#classObject   = this;
   }

   #setErr(name, obj = {}) {
      this.errorMsgs.push({'name': name, 'err': obj.errMsg});

      let errEl = this.#createErrElement(obj);

      this.#addErrClasses();
      this.#removeOldErr();
      this.#appendErrElement(errEl)
   }

   #setValid() {
      if (isEmpty(this.#element)) return;

      if (this.#errMsgNextParent) this.#element.parentElement.classList.remove(this.#inputErrClassName);
      this.#element.classList.remove(this.#inputErrClassName);

      if (this.#nextElement) {
         if (this.#nextElement.classList.contains(this.#errMsgClassName)) this.#nextElement.remove();
      }
   }

   #createErrElement(obj) {
      let errEl = document.createElement('span');
      errEl.classList.add(this.#errMsgClassName);

      let errorMsg = '';
      obj.errMsg.forEach(msg => errorMsg += i18n.global.t(`${msg}`) + ' ');
      errEl.textContent = errorMsg;

      return errEl;
   } 
   
   #addErrClasses() {
      if (this.#errMsgNextParent) this.#element.parentElement.classList.add(this.#inputErrClassName);
      this.#element.classList.add(this.#inputErrClassName);
   }

   #removeOldErr() {
      if (this.#nextElement) {
         if (this.#nextElement.classList.contains(this.#errMsgClassName)) this.#nextElement.remove();
      }
   }

   #appendErrElement(errEl) {
      if (this.#errMsgNextParent) this.#element.parentElement.insertAdjacentElement('afterend', errEl);
      else this.#element.parentNode.insertBefore(errEl, this.#element.nextElementSibling);
   }

   addErrNextParent() {
      this.#errMsgNextParent = true;
   }

   #reset() {
      this.#errMsgNextParent = false;
      this.#objects          = [];
   }

   // ====================================== custom errors can add on any input
   addCustomErr(name, errMsg) {
      let obj = {errMsg};
      this.#setProps(name);
      this.#setErr(name, obj);
   }

   removeErr(name) {
      this.#setProps(name);
      this.#setValid();
   }
}