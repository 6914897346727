<template>
   <div :class="[parentClass, 'input-wrapper my-1']">
      <button v-if="labelBtn" :class="labelBtnClass" v-html="labelBtnTxt" :id="labelBtnId || null " @click="btnClick"></button>
      
      <label 
         :for="id ?? name" 
         class="mb-1 w-fit" 
         @click="labelClick"
      >
         <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~ label -->
         <span v-if="label" class="label" :class="labelClass">{{ $t(label) }}</span>

      
         <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~ input -->
         <input
            class="form-control"
            :type="type"
            :name="name"
            :value="modelValue ?? ''"
            :id="id ?? name"
            :placeholder="$t(placeholder)"
            :disabled="isDisabled"
   
            v-bind="$attrs"
            v-focus="isFocus"
   
            @click="click"
            @change="change"
         />


         <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~ checkbox style as light turning on and off -->
         <template v-if="checkboxStyle == 'light-switch'">
            <span class="switch-left">{{ $t(checkboxSwitchTextLeft) }}</span>
            <span class="switch-right">{{ $t(checkboxSwitchTextRight) }}</span>
         </template>
   
         <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~ checkbox style as ball slide -->
         <template v-if="checkboxStyle == 'slide-switch'">
            <span class="slider"></span>
         </template>

      </label>
   </div>
</template>


<script>
   import { onMounted, watch, onUpdated} from 'vue';
   import { useStore }  from 'vuex';

   export default {
      name: 'CheckboxInput',

      props: {
         // ################################ input props
         type:        {type: String,default: 'checkbox'},
         name:        {type: String,default: ''},
         modelValue:  {type: [String, null], default: NOT_ACTIVE_IN_DB},
         id:          {type: [String, null], default: null},
         placeholder: {type: String,default: '',},
         isDisabled:  {type: Boolean,default: false,},
         isLazy:      {type: Boolean,default: false},
         isFocus:     {type: Boolean,default: false},
         isChecked:   {type: Boolean,default: false},
         
         // ################################ input checkbox
         checkboxStyle:           {type: String, default: 'light-switch'},
         isCheckboxSwitch:        {type: Boolean,default: true},
         fsCheckboxLightSwitch:   {type: String,default: '.65rem'},
         fsCheckboxSlideSwitch:   {type: String,default: '1rem'},
         checkboxSwitchTextLeft:  {type: String,default: 'ON'},
         checkboxSwitchTextRight: {type: String,default: 'OFF'},


         // ################################ input events
         click:    {type: Function,default: null},
         change:   {type: Function,default: null},
         
         // ################################ parent props 
         parentClass: {type: String, default: ''},


         // ################################ label props 
         labelClass: { type: String, default: ''},
         label:      { type: [String, Boolean], default: false}, 
         labelIcon : { type: String, default: '',},
         labelHTML:  { type: Boolean, default: false,},


         // ################################ label button props 
         labelBtn:      { type: Boolean, default: false},
         labelBtnTxt:   { type: String, default: "<i class='fa-solid fa-magnifying-glass'></i>"},
         labelBtnId:    { type: [String, Boolean], default: false},
         labelBtnClass: { type: String, default: 'btn btn-sm btn-primary'},
         labelClick:    { type: Function, default: null,},
         btnClick:      { type: Function, default: null,},


         // ################################ extra element adding if needed
         hasExtraEl:    { type: Boolean, default: false},
         extraElClass:  { type: String, default: null},
         extraElId:     { type: String, default: null},
         extraElTxt:    { type: String, default: ''},
         radioBoxStyle: { type: String, default: '',},

         
         // ################################ layout
         labelRow:           { type: [Boolean, String], default: false }, 
         labelRowBreakPoint: { type: String, default: 'md' },

      },

      setup(props, context) {
         const type     = props.type;
         const attrs    = context.attrs;
         const store    = useStore();
         const lang     = store.getters['config/getLang'];

         onMounted(() => {
            const selector  = `.input-wrapper #${props.id}`;
            const input     = document.querySelector(selector);
            const wrapper   = input.parentElement.parentElement;
            const label     = wrapper.querySelector('label');
            const spanLabel = wrapper.querySelector('.label');

            input.addEventListener('change', function() {
               if (this.checked) context.emit('isChecked')
            });

            // if type is checkbox or radio remove & add some classes (Bootstrap) & emit event when the input changed
            input.classList.remove('form-control');
            label.classList.add('form-check');
            input.classList.add('form-check-input');
            if (label) label.classList.add('form-check-label');

            // remove & add attributes from parent element
            for (let key in context.attrs) {
               if (key == 'onUpdate:modelValue') continue;
               if (key == 'parentClass') input.removeAttribute(key);
               
               if (key == 'class') wrapper.className = 'input-wrapper my-1 ' + props.parentClass;
               else if (key != 'parentClass') wrapper.removeAttribute(key);
            }

            // to watch if class has changed dynamical
            onUpdated(()=> {
               if (!isEmpty(props.labelRow) && props.labelRow !== false) wrapper.className = 'input-wrapper my-1 row align-items-center ' + props.parentClass;
            })

            // update input value => method based on some attributes & input type
            input.checked = props.isChecked || (typeof props.modelValue === 'string' ? (props.modelValue == 'f' ? false : true) : props.modelValue);

            input.setAttribute('checked', props.modelValue);
            if (props.isChecked || props.modelValue) context.emit('update:modelValue', ACTIVE_IN_DB);
            else context.emit('update:modelValue', NOT_ACTIVE_IN_DB);

            input.addEventListener('change', () => {
               context.emit('update:modelValue', input.checked ? ACTIVE_IN_DB : NOT_ACTIVE_IN_DB);
            });

            if (spanLabel) {
               if (spanLabel.classList.contains('mb-0') || spanLabel.classList.contains('my-0')) {
                  spanLabel.classList.remove('mb-1', 'mb-2', 'mb-3', 'mb-4', 'mb-5');
               }
            }

            if (wrapper) {
               if (wrapper.classList.contains('my-0')) wrapper.classList.remove('my-1', 'my-2', 'my-3', 'my-4', 'my-5');
               if (wrapper.classList.contains('my-2')) wrapper.classList.remove('my-1', 'my-3', 'my-4', 'my-5');
               if (wrapper.classList.contains('my-3')) wrapper.classList.remove('my-1', 'my-2', 'my-4', 'my-5');
               if (wrapper.classList.contains('my-4')) wrapper.classList.remove('my-1', 'my-2', 'my-3', 'my-5');
               if (wrapper.classList.contains('my-5')) wrapper.classList.remove('my-1', 'my-2', 'my-3', 'my-4');
            }
            
            // if the model changed then make the right input checked 
            wrapper.style.position = 'relative';
            watch(()=> props.modelValue, (newValue) => {
               if (newValue === input.value.trim()) input.checked = true; 
            });

            // checkbox light switch
            if (props.checkboxStyle == 'light-switch') {
               let div            = document.createElement('div');
               div.className      = 'light-switch';
               div.style.fontSize = props.fsCheckboxLightSwitch;

               let switchLeft  = wrapper.querySelector('.switch-left');
               let switchRight = wrapper.querySelector('.switch-right');

               div.appendChild(input);
               div.appendChild(switchLeft);
               div.appendChild(switchRight);
               
               label.style.cssText =`
                  display: flex;
                  align-items: center;
                  gap: .35rem;
                  padding-right: 0;
               `;

               if (label) {
                  label.classList.remove('mb-1', 'mb-2', 'mb-3', 'mb-4', 'mb-5');
                  label.classList.remove('my-1', 'my-2', 'my-3', 'my-4', 'my-5');
                  label.style.textAlign = "center";
               }

               label.prepend(div);
            }

            // checkbox slide switch
            if (props.checkboxStyle == 'slide-switch') {
               let div        = document.createElement('div');
               div.className  = 'slide-switch';
               div.style.fontSize = props.fsCheckboxSlideSwitch;

               let slider = wrapper.querySelector('.slider');

               div.appendChild(input);
               div.appendChild(slider);
               
               label.style.cssText = `
                  display: flex;
                  align-items: center;
                  gap: .35rem;
                  padding-right: 0;
               `;

               if (label) {
                  label.classList.remove('mb-1', 'mb-2', 'mb-3', 'mb-4', 'mb-5');
                  label.classList.remove('my-1', 'my-2', 'my-3', 'my-4', 'my-5');
                  label.style.textAlign = "center";
               }

               label.prepend(div);
            }

            // layout
            if (!isEmpty(props.labelRow) && props.labelRow !== false) {
               if (isNaN(parseInt(props.labelRow))) return;

               let cols            = 12;
               let labelCol        = props.labelRow;
               let inputWrapperCol = cols - labelCol;

               let div            = document.createElement('div');
               let labelDiv       = document.createElement('div');
               div.className      = `col-${props.labelRowBreakPoint}-${inputWrapperCol}`;
               labelDiv.className = `col-${props.labelRowBreakPoint}-${labelCol} position-relative`;

               div.appendChild(input);
               labelDiv.appendChild(label);

               wrapper.appendChild(labelDiv);
               wrapper.appendChild(div);
               wrapper.classList.add('row', 'align-items-center');

               label.classList.add('col-form-label', 'd-block', 'mx-auto', 'mw-fit', 'text-center');
               label.classList.remove('mb-1', 'mb-2', 'mb-3', 'mb-4', 'mb-5', 'mb-6');
            }
         });
      }
   }
</script>