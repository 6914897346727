<template>
   <div :class="[parentClass, 'input-wrapper my-1']">
      <button v-if="labelBtn" :class="labelBtnClass" v-html="labelBtnTxt" :id="labelBtnId || null " @click="btnClick"></button>
      
      <label 
         v-if="label" 
         :for="id ?? name" 
         class="mb-1" 
         :class="labelClass" 
         v-html="labelHTML ? label : this.$t(label) + `${labelIcon}`"
         @click="labelClick"
      >
      </label>

      <input
         class="form-control"
         :class="isTdInput ? 'td-input' : ''"
         type="text"
         :name="name"
         :value="modelValue ?? ''"
         :id="id ?? name"
         :placeholder="$t(placeholder)"
         :disabled="isDisabled"
         :maxlength="maxlength"

         :data-default-val-zero="isZeroEmptyInt || undefined"

         v-bind="$attrs"
         v-only-int="{isInt, isAllowedNegativeValue}"
         v-float-int="{isFloat, isAllowedNegativeValue}"
         v-focus="isFocus"
         v-val-zero-empty-int="isZeroEmptyInt"

         @focus="focus"
         @blur="blur"
         @click="click"
         @change="change"
      />


      <span v-if="hasExtraEl" :class="extraElClass" :id="extraElId" v-html="extraElTxt"></span>
   </div>
</template>


<script>
   import { onMounted, onUpdated } from 'vue';
   import { useStore }  from 'vuex';

   export default {
      name: 'BaseInput',

      props: {
         // ################################ input props
         type:        {type: String,default: 'text'},
         name:        {type: String,default: ''},
         modelValue:  {type: [String, null],default: null,},
         id:          {type: [String, null],default: null,},
         placeholder: {type: String,default: '',},

         isInt:   {type: Boolean,default: false,},
         isFloat: {type: Boolean,default: false,},

         isZeroEmptyInt:         {type: Boolean,default: false},
         isAllowedNegativeValue: {type: Boolean,default: false},
         isLazyZeroEmptyInt:     {type: Boolean,default: true},

         isTdInput: {type: Boolean,default: false,},

         isDisabled:  {type: Boolean,default: false,},
         isLazy:      {type: Boolean,default: false},
         isShowPwd:   {type: Boolean,default: false,},
         isFocus:     {type: Boolean,default: false},

         isShowPhoneCount: {type: Boolean,default: false,},
         maxlength:        {type: [Number, Boolean] , default: false},

         // ################################ input events
         click:    {type: Function,default: null},
         keyup:    {type: Function,default: null},
         keydown:  {type: Function,default: null},
         keypress: {type: Function,default: null},
         input:    {type: Function,default: null},
         focus:    {type: Function,default: null},
         blur:     {type: Function,default: null},

         // ################################ parent props 
         parentClass: {type: String, default: ''},


         // ################################ label props 
         labelClass: { type: String, default: ''},
         label:      { type: [String, Boolean], default: false}, 
         labelIcon : { type: String, default: '',},
         labelHTML:  { type: Boolean, default: false,},


         // ################################ label button props 
         labelBtn:      { type: Boolean, default: false},
         labelBtnTxt:   { type: String, default: "<i class='fa-solid fa-magnifying-glass'></i>"},
         labelBtnId:    { type: [String, Boolean], default: false},
         labelBtnClass: { type: String, default: 'btn btn-sm btn-primary'},
         labelClick:    { type: Function, default: null,},
         btnClick:      { type: Function, default: null,},


         // ################################ extra element adding if needed
         hasExtraEl:    { type: Boolean, default: false},
         extraElClass:  { type: String, default: null},
         extraElId:     { type: String, default: null},
         extraElTxt:    { type: String, default: ''},
         radioBoxStyle: { type: String, default: '',},

         
         // ################################ layout
         labelRow:           { type: [Boolean, String], default: false }, 
         labelRowBreakPoint: { type: String, default: 'md' },
         labelRowTextCenter: { type: Boolean, default: false },
         labelRowBreakPointExists: { type: Boolean, default: false },
      },

      setup(props, context) {
         const type     = 'text';
         const attrs    = context.attrs;
         const store    = useStore();
         const lang     = store.getters['config/getLang'];

         function showPwd(icon) {
            const input = document.querySelector(`.input-wrapper [name="${props.name}"]`);
            let attr    = input.getAttribute('type');

            if (attr == 'password') input.setAttribute('type', 'text');
            else input.setAttribute('type', 'password');
            
            icon.target.classList.toggle('fa-eye-slash');
            icon.target.classList.toggle('fa-eye');
         }

         function countPhoneNumber(el) {
            let phoneNumberCount  = parseFloat(props.maxlength);
            let input             = el;
            let span              = input.nextElementSibling;

            if (input.value.trim().length !== 0) span.innerHTML = phoneNumberCount - input.value.length;
         
            input.addEventListener('keyup', function() {
               span.innerHTML = phoneNumberCount - this.value.length;
         
               if (span.innerHTML < 0) span.innerHTML = 0;
               if (span.innerHTML > phoneNumberCount) span.innerHTML = phoneNumberCount;
            });

            input.addEventListener('blur', function() {
               if (input.value.trim().length === 0) span.innerHTML = phoneNumberCount;
            })
         }

         onMounted(() => {
            const selector = `.input-wrapper [name="${props.name}"]`;
            const input    = document.querySelector(selector);
            const wrapper  = input.parentElement;
            const label    = wrapper.querySelector('label');

            // remove & add attributes from parent element
            for (let key in context.attrs) {
               if (key == 'onUpdate:modelValue') continue;
               if (key == 'parentClass') input.removeAttribute(key);
               
               if (key == 'class') wrapper.className = 'input-wrapper my-1 ' + props.parentClass;
               else if (key != 'parentClass') wrapper.removeAttribute(key);
            }

            // to watch if class has changed dynamical
            onUpdated(()=> {
               if (!isEmpty(props.labelRow) && props.labelRow !== false) wrapper.className = 'input-wrapper my-1 row align-items-center ' + props.parentClass;
            });


            // set input value with the model value
            if (props.modelValue !== null) input.value = props.modelValue;

            // update input value => method based on some attributes & input type
            if (props.isLazy || (props.isZeroEmptyInt && props.isLazyZeroEmptyInt)) 
               input.addEventListener('blur', ()=> context.emit('update:modelValue', input.value));
            else 
            input.addEventListener('input', ()=> context.emit('update:modelValue', input.value));


            if (label) {
               if (label.classList.contains('mb-0') || label.classList.contains('my-0')) {
                  label.classList.remove('mb-1', 'mb-2', 'mb-3', 'mb-4', 'mb-5');
               }
            }

            if (wrapper) {
               if (wrapper.classList.contains('my-0')) wrapper.classList.remove('my-1', 'my-2', 'my-3', 'my-4', 'my-5');
               if (wrapper.classList.contains('my-2')) wrapper.classList.remove('my-1', 'my-3', 'my-4', 'my-5');
               if (wrapper.classList.contains('my-3')) wrapper.classList.remove('my-1', 'my-2', 'my-4', 'my-5');
               if (wrapper.classList.contains('my-4')) wrapper.classList.remove('my-1', 'my-2', 'my-3', 'my-5');
               if (wrapper.classList.contains('my-5')) wrapper.classList.remove('my-1', 'my-2', 'my-3', 'my-4');
            }

            // add events on editing modal
            if (props.isInt) input.setAttribute('data-only-int', true);
            if (props.isFloat) input.setAttribute('data-float-int', true);
            

            // input events 
            if (props.input    != null) input.addEventListener('input', props.input);
            if (props.keyup    != null) input.addEventListener('keyup', props.keyup);
            if (props.keypress != null) input.addEventListener('keypress', props.keypress);
            if (props.keydown  != null) input.addEventListener('keydown', props.keydown);
            

            // show password or count number
            let className    = props.isShowPhoneCount ? 'count-phone-number' : 'show-pwd';
            let elementTag   = props.isShowPhoneCount ? 'span' : 'i';
            let elementClass = props.isShowPhoneCount ? '' : 'show-pwd-icon fa-solid fa-eye';
            let elementValue = props.isShowPhoneCount ? props.maxlength : '';

            if (props.isShowPhoneCount || props.isShowPwd) {
               let div       = document.createElement('div');
               div.className = className;

               let element = document.createElement(elementTag);
               element.className = elementClass;
               element.innerHTML = elementValue;

               div.appendChild(input);
               div.appendChild(element)

               wrapper.appendChild(div);

               if (props.isShowPwd) {
                  input.type = 'password';
                  element.setAttribute('data-show-pwd-icon', true);
                  element.addEventListener('click', showPwd);
                  
               } else {
                  input.setAttribute('data-show-phone-count', true);
                  countPhoneNumber(input);
               }
            }


            // layout
            if (!isEmpty(props.labelRow) && props.labelRow !== false) {
               if (isNaN(parseInt(props.labelRow))) return;

               let cols            = 12;
               let labelCol        = props.labelRow;
               let inputWrapperCol = cols - labelCol;

               let div            = document.createElement('div');
               let labelDiv       = document.createElement('div');
               div.className      = props.labelRowBreakPointExists ? `col-${inputWrapperCol}` : `col-${props.labelRowBreakPoint}-${inputWrapperCol}`;
               labelDiv.className = props.labelRowBreakPointExists ? `col-${labelCol} position-relative` : `col-${props.labelRowBreakPoint}-${labelCol} position-relative`;

               if (!props.isShowPhoneCount && !props.isShowPwd) div.appendChild(input);
               else div.appendChild(input.parentElement);
               labelDiv.appendChild(label);

               wrapper.appendChild(labelDiv);
               wrapper.appendChild(div);
               wrapper.classList.add('row', 'align-items-center');
               label.classList.add('col-form-label', 'd-block', 'mw-fit');
               if (props.labelRowTextCenter) label.classList.add(`mx-${props.labelRowBreakPoint}-auto`);
               label.classList.remove('mb-1', 'mb-2', 'mb-3', 'mb-4', 'mb-5', 'mb-6');
            }

         });
      }
   }
</script>