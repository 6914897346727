<template>
   <h2 class="main-heading" id="main-heading">
      <slot></slot>
   </h2>
</template>

<script>
export default {
   name: 'MainHeading',
}
</script>

<style lang="scss" scoped>
   @use '@sass/utils' as *;
   @use '@sass/variables' as *;

   .main-heading {
      text-transform: capitalize;
      font-size: rem(25);
   }
</style>