<template>
   <div :class="[parentClass, 'input-wrapper my-1', searchStyle]">
      <button v-if="labelBtn" @click="btnClick" :class="[`${searchStyle}__btn`, labelBtnClass]" v-html="labelBtnTxt" :id="labelBtnId || null "></button>
      
      <label 
         v-if="label" 
         :for="id ?? name" 
         class="mb-1" 
         :class="[`${searchStyle}__label`, labelClass]" 
         v-html="labelHTML ? label : this.$t(label) + `${labelIcon}`"
         @click="labelClick"
      >
      </label>

      <input
         :class="`form-control ${searchStyle}__input ${isTdInput ? 'td-input' : ''}`"
         :type="type"
         :name="name"
         :value="modelValue ?? ''"
         :id="id ?? name"
         :placeholder="$t(placeholder)"
         :disabled="isDisabled"

         v-only-int="{isInt, isAllowedNegativeValue}"
         v-float-int="{isFloat, isAllowedNegativeValue}"

         v-bind="$attrs"
         v-focus="isFocus"

         @focus="focus"
         @blur="blur"
         @click="click"
         @change="change"
      />

      <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~ extra element  -->
      <span v-if="hasExtraEl" :class="extraElClass" :id="extraElId" v-html="extraElTxt"></span>
      
   </div>
</template>


<script>
   import { onMounted, onUpdated } from 'vue';
   import { useStore }  from 'vuex';

   export default {
      name: 'BaseInput',

      props: {
         // ################################ input props
         type:        {type: String,default: 'search'},
         name:        {type: String,default: ''},
         modelValue:  {type: [String, null],default: null,},
         id:          {type: [String, null],default: null,},
         placeholder: {type: String,default: '',},
         searchStyle: {type: String, default: 'skeleton-search'},

         isInt:   {type: Boolean,default: false},
         isFloat: {type: Boolean,default: false},
         isAllowedNegativeValue: {type: Boolean,default: false},

         isTdInput: {type: Boolean,default: false},

         isDisabled:  {type: Boolean,default: false,},
         isLazy:      {type: Boolean,default: false},
         isFocus:     {type: Boolean,default: false},

         // ################################ input events
         click:    {type: Function,default: null},
         keyup:    {type: Function,default: null},
         keydown:  {type: Function,default: null},
         keypress: {type: Function,default: null},
         input:    {type: Function,default: null},
         focus:    {type: Function,default: null},
         blur:     {type: Function,default: null},

         // ################################ parent props 
         parentClass: {type: String, default: ''},


         // ################################ label props 
         labelClass: { type: String, default: ''},
         label:      { type: [String, Boolean], default: false}, 
         labelIcon : { type: String, default: '',},
         labelHTML:  { type: Boolean, default: false,},


         // ################################ label button props 
         labelBtn:      { type: Boolean, default: true},
         labelBtnTxt:   { type: String, default: "<i class='fa-solid fa-magnifying-glass'></i>"},
         labelBtnId:    { type: [String, Boolean], default: false},
         labelBtnClass: { type: String, default: ''},
         labelClick:    { type: Function, default: null,},
         btnClick:      { type: Function, default: null,},

         // ################################ extra element adding if needed
         hasExtraEl:    { type: Boolean, default: false},
         extraElClass:  { type: String, default: null},
         extraElId:     { type: String, default: null},
         extraElTxt:    { type: String, default: ''},
         radioBoxStyle: { type: String, default: '',},


         
         // ################################ layout
         labelRow:           { type: [Boolean, String], default: false }, 
         labelRowBreakPoint: { type: String, default: 'md' },
      },

      setup(props, context) {
         const type     = props.type;
         const attrs    = context.attrs;
         const store    = useStore();
         const lang     = store.getters['config/getLang'];

         onMounted(() => {
            const selector = `.input-wrapper [name="${props.name}"]`;
            const input    = document.querySelector(selector);
            const wrapper  = input.parentElement;
            const label    = wrapper.querySelector('label');

            // remove & add attributes from parent element
            for (let key in context.attrs) {
               if (key == 'onUpdate:modelValue') continue;
               if (key == 'parentClass') input.removeAttribute(key);
               
               if (key == 'class') wrapper.className = `input-wrapper my-1 ${props.parentClass} ${props.searchStyle}`;
               else if (key != 'parentClass') wrapper.removeAttribute(key);
            }

            // to watch if class has changed dynamical
            onUpdated(()=> {
               if (!isEmpty(props.labelRow) && props.labelRow !== false) wrapper.className = `input-wrapper my-1 row align-items-center ${props.parentClass} ${props.searchStyle}`;
            })


            // set input value with the model value
            if (props.modelValue !== null) input.value = props.modelValue;

            // update input value => method based on some attributes & input type
            if (props.isLazy) 
               input.addEventListener('blur', ()=> context.emit('update:modelValue', input.value));
            else 
               input.addEventListener('input', ()=> context.emit('update:modelValue', input.value));


            if (label) {
               if (label.classList.contains('mb-0') || label.classList.contains('my-0')) {
                  label.classList.remove('mb-1', 'mb-2', 'mb-3', 'mb-4', 'mb-5');
               }
            }

            if (wrapper) {
               if (wrapper.classList.contains('my-0')) wrapper.classList.remove('my-1', 'my-2', 'my-3', 'my-4', 'my-5');
               if (wrapper.classList.contains('my-2')) wrapper.classList.remove('my-1', 'my-3', 'my-4', 'my-5');
               if (wrapper.classList.contains('my-3')) wrapper.classList.remove('my-1', 'my-2', 'my-4', 'my-5');
               if (wrapper.classList.contains('my-4')) wrapper.classList.remove('my-1', 'my-2', 'my-3', 'my-5');
               if (wrapper.classList.contains('my-5')) wrapper.classList.remove('my-1', 'my-2', 'my-3', 'my-4');
            }

            // input events 
            if (props.input    != null) input.addEventListener('input', props.input);
            if (props.keyup    != null) input.addEventListener('keyup', props.keyup);
            if (props.keypress != null) input.addEventListener('keypress', props.keypress);
            if (props.keydown  != null) input.addEventListener('keydown', props.keydown);


            // layout
            if (!isEmpty(props.labelRow) && props.labelRow !== false) {
               if (isNaN(parseInt(props.labelRow))) return;

               let cols            = 12;
               let labelCol        = props.labelRow;
               let inputWrapperCol = cols - labelCol;

               let div            = document.createElement('div');
               let labelDiv       = document.createElement('div');
               div.className      = `col-${props.labelRowBreakPoint}-${inputWrapperCol}`;
               labelDiv.className = `col-${props.labelRowBreakPoint}-${labelCol} position-relative`;

               div.appendChild(input);
               labelDiv.appendChild(label);

               wrapper.appendChild(labelDiv);
               wrapper.appendChild(div);
               wrapper.classList.add('row', 'align-items-center');

               label.classList.add('col-form-label', 'd-block', 'mx-auto', 'mw-fit', 'text-center');
               label.classList.remove('mb-1', 'mb-2', 'mb-3', 'mb-4', 'mb-5', 'mb-6');
            }
         });
      }
   }
</script>