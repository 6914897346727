import i18n          from "@/language/i18n";
import Validation    from '@/validation/ValidationTrigger';
import store         from '@/store/store';
import localeEn      from 'air-datepicker/locale/en';
import AirDatepicker from 'air-datepicker';

const t = i18n.global.t;

export class Modal {
   #lang;
   #defaultConfirmBtnClass = 'btn-pushable-color-primary w-100';

   constructor() {
      this.#lang  = store.getters['config/getLang'];
   }
   
   async popup(options = {}) {
      const self = this;

      const { value } = await Swal.fire({
         title: t(options.title ?? 'lang_edit'),
         html: options.html,
         customClass: { 
            confirmButton: options.confirmBtnClass ?? this.#defaultConfirmBtnClass,
            header: 'align-items-start',
         },
         showClass: {
            popup: 'swal2-show animate__zoomIn',
         },
         hideClass: {
            popup: 'swal2-hide animate__zoomOut',
         },

         buttonsStyling: true,
         showCloseButton: true,
         focusDeny: false,
         focusCancel: false,
         focusConfirm: false,
         confirmButtonText:  `
            <span class="front">
               ${t(options.confirmBtnTxt ?? 'lang_update')}
            </span>
         `,
         closeButtonHtml: '<i class="fa-solid fa-xmark"></i>',

         preConfirm() {return self.#preConfirm(options)},
         allowOutsideClick() {return self.#allowOutsideClick(options) },
         willOpen() {self.#willOpen(options)},
         didRender() {self.#didRender(options)},
      });

      if (value) return value;
      else return {};
   }

   #allowOutsideClick(options) {
      if (options.clickOutside) return true;

      const popup = Swal.getPopup();

      popup.classList.remove('swal2-show');

      setTimeout(() => {
         popup.classList.remove('animate__zoomIn');
         popup.classList.add('animate__animated', 'animate__headShake');
      });

      setTimeout(() => popup.classList.remove('animate__animated', 'animate__headShake'), 500);
      
      return false
   }

   #preConfirm(options) {
      if (!options.parent) return;

      if (options.validate ?? true) {
         
         let validationOptions = deepCopyArray(options.validationOption);

         validationOptions.forEach(rule => {
            rule.name = rule.name.replace('Edit', '');
            rule.name = rule.name + 'Edit';
         });

         let validation = new Validation();
         if (!validation.validating(validationOptions)) return false;
      }

      const inputs = document.querySelector(options.parent).querySelectorAll('input, textarea, select');
      let values   = {};

      inputs.forEach(el => {

         if (el.type == 'radio') {
            if (el.checked == true) {
               values[el.name.replace('Edit', '')]  = el.value
            }

            return;
         } 

         if (el.type == 'checkbox') {
            if (el.checked == true) values[el.name.replace('Edit', '')]  = '1';
            else values[el.name.replace('Edit', '')]  = 'f';

            return;
         } 
         
         values[el.name.replace('Edit', '')]  = el.value
      });

      if (options.updateFunction ?? false) {
         if (options.img ?? false) values[options.img.name] = options.img.file;
         
         options.updateFunction(values);
         return false;
      }

      return values;
   }

   #didRender(options) {
      if (!options.parent) return;

      // ########## change some content style
      document.querySelectorAll('.swal2-content .input-wrapper').forEach(element => {
         
         if (element.classList.contains('my-0')) element.classList.remove('my-1', 'my-2', 'my-3', 'my-4', 'my-5');
         if (element.classList.contains('my-1')) element.classList.remove('my-2', 'my-3', 'my-4', 'my-5');
         if (element.classList.contains('my-2')) element.classList.remove('my-1', 'my-3', 'my-4', 'my-5');
         if (element.classList.contains('my-3')) element.classList.remove('my-1', 'my-2', 'my-4', 'my-5');
         if (element.classList.contains('my-4')) element.classList.remove('my-1', 'my-2', 'my-3', 'my-5');
         if (element.classList.contains('my-5')) element.classList.remove('my-1', 'my-2', 'my-3', 'my-4');

      })

      document.querySelector('.swal2-content').style.textAlign = 'start';

      if (document.querySelector(options.parent + 'form')) document.querySelector(options.parent + 'form').className = '';

      if (document.querySelector(options.parent +' input[type="date"]')) {

         document.querySelectorAll(options.parent +' input[type="date"]').forEach(el => {
            let input   = el;

            input.setAttribute('type','text');
            input.setAttribute('readonly','');

            new AirDatepicker(input, {
               locale: localeEn,
               dateFormat: 'yyyy-MM-dd',
               keyboardNav: true,
               autoClose: true,
               position: this.#lang == 'en' ? 'bottom left' : 'bottom right',
               isMobile: window.innerWidth <= 768 ? true : false,
               buttons: ['clear'],
            });
         });

      }

      // ########## input events
      if (options.events) {
         options.events.forEach(event => {
            document.querySelectorAll(options.parent + ' ' + event.element).forEach(element => {
               element.addEventListener(event.name, event.method);
            });
         })
      }

      if (document.querySelector(options.parent +' .radioToCheckBox')) {

         document.querySelectorAll(options.parent +' .radioToCheckBox').forEach(el => {
            let parent = el.parentElement;
            let input  = parent.querySelector('input[type="radio"]');
            
            el.onclick = ()=> input.click();
         });
   
      }

      if (document.querySelector(options.parent +' .input-wrapper .switch')) {

         document.querySelectorAll(options.parent + ' .input-wrapper .switch .switch-right').forEach(el => {
            let parent = el.parentElement;
            let input  = parent.querySelector('input[type="checkbox"]');
            
            el.onclick = ()=> input.click();
         });

         document.querySelectorAll(options.parent + ' .input-wrapper .switch .switch-left').forEach(el => {
            let parent = el.parentElement;
            let input  = parent.querySelector('input[type="checkbox"]');
            
            el.onclick = ()=> input.click();
         });

      }

      // ########## input rules
      if (document.querySelector(options.parent + ' input[data-only-int]')) {
         document.querySelectorAll(options.parent + ' input[data-only-int]:not([data-allow-negative])').forEach(inp => {
            inp.addEventListener('input', function() {
               this.value = this.value.replace(/[^0-9]/g, '');
            });
         });
         document.querySelectorAll(options.parent + ' input[data-only-int][data-allow-negative]').forEach(inp => {
            inp.addEventListener('input', function() {
               this.value = this.value.replace(/[^-?0-9]/g, '');
            });
         });
      }

      if (document.querySelector(options.parent + ' input[data-float-int]')) {
         document.querySelectorAll(options.parent + ' input[data-float-int]:not([data-allow-negative])').forEach(inp => {
            inp.addEventListener('input', function() {
               if (this.value.trim() == '.') this.value = '';
               this.value = this.value.replace(/[^0-9\.]/g, '');
            });
         });
         document.querySelectorAll(options.parent + ' input[data-float-int][data-allow-negative]').forEach(inp => {
            inp.addEventListener('input', function() {
               if (this.value.trim() == '.') this.value = '';
               this.value = this.value.replace(/[^-?0-9\.]/g, '');
            });
         });
      }

      if (document.querySelector(options.parent + ' input[data-val-zero-empty-int]')) {
         document.querySelectorAll(options.parent + ' input[data-val-zero-empty-int]').forEach(inp => {
            if (inp.value.trim() == '') inp.value = 0;
      
            inp.addEventListener('focus', function() {
               if (inp.value == '') inp.setAttribute('input-val', 0);
               else inp.setAttribute('input-val', inp.value);
   
               inp.value = '';
            });
   
            inp.addEventListener('blur', function() {
               if (inp.value.trim() == '') {
                  inp.value = inp.getAttribute('input-val');
                  inp.removeAttribute('input-val');
               }
            });
         });
      }

      // ########## some events like show password & phone number & live image
      if (document.querySelector(options.parent + ' [data-show-pwd-icon]')) {
         document.querySelector(options.parent + ' [data-show-pwd-icon]').addEventListener('click', function () {
            let input = this.parentElement.querySelector('input');
            let attr  = input.getAttribute('type');

            if (attr == 'password') input.setAttribute('type', 'text');
            else input.setAttribute('type', 'password');
            
            this.classList.toggle('fa-eye-slash');
            this.classList.toggle('fa-eye');
         });
      };

      if (document.querySelector(options.parent + ' input[data-show-phone-count]')) {
         document.querySelectorAll(options.parent + ' input[data-show-phone-count]').forEach(input => {
            let span             = input.nextElementSibling;
            let phoneNumberCount = 11;

            if (input.value.trim().length !== 0) span.innerHTML = phoneNumberCount - input.value.length;
      
            input.addEventListener('keyup', function() {
               span.innerHTML = phoneNumberCount - this.value.length;
         
               if (span.innerHTML < 0) span.innerHTML = 0;
               if (span.innerHTML > 11) span.innerHTML = 11;
            });
   
            input.addEventListener('blur', function() {
               if (input.value.trim().length === 0) span.innerHTML = phoneNumberCount;
            });
         });
      }

      if (document.querySelector(options.parent + ' input[data-input-live-img]')) {
         document.querySelectorAll(options.parent + ' input[data-input-live-img]').forEach(inp => {
            inp.addEventListener('change', function(e) {

               let output = document.querySelector(options.parent + ' ' + this.getAttribute('data-input-live-img')) || false;
               if (!output) return;

               let file = e.target.files[0];

               options.img = {
                  file,
                  name: inp.getAttribute('id').replace('Edit', '')
               };

               output.setAttribute('src', URL.createObjectURL(file));
               output.addEventListener('load', function () {
                  URL.revokeObjectURL(output.src) // free memory
               });
            });
         });
      }
   }

   #willOpen(options) {
      if (!options.parent) return;

      const submitBtn = document.querySelector('.swal2-confirm');
      const inputs    = document.querySelector(options.parent).querySelectorAll('input, textarea');

      inputs.forEach(input => {
         input.onkeyup = (e) => {
            if (e.keyCode == 13) submitBtn.click();
         }
      });
   }

   close() {
      Swal.close();
   }
}