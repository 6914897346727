import classMap from '@/utils/classes/includeClass';

export class Classes {

   // if there is class with name then return new instance .. if not show error;
   static getClass(className) {
      if (classMap.hasOwnProperty(className)) return new classMap[className]();
      else return new Error(`Class ${className} not found`);
   }
   
}