
<template>
   <div :class="[parentClass, 'input-wrapper my-1']">
      <label 
         v-if="label" 
         :for="id ?? name" 
         class="mb-1" 
         :class="labelClass" 
         v-html="labelHTML ? label : this.$t(label) + `${labelIcon}`"
         @click="labelClick"
      >
      </label>

      <select      
         class="form-select"
         :class="isTdSelectBox ? 'td-select' : ''"
         :name="name"
         :value="modelValue ?? ''"
         :id="id ?? name"
         :placeholder="$t(placeholder)"
         :disabled="isDisabled"
         v-bind="$attrs"

         @change="change"
         @click="click"
      >
         <slot></slot>
      </select>
   </div>
</template>


<script>
   import { onMounted, onUpdated} from 'vue';
   import { useStore }  from 'vuex';

   export default {
      name: 'BaseInput',

      props: {
         // ################################ input props
         type:        {type: String,default: 'text'},
         name:        {type: String,default: ''},
         modelValue:  {type: [String, null],default: null,},
         id:          {type: [String, null],default: null,},
         placeholder: {type: String,default: '',},

         isDisabled:    {type: Boolean,default: false,},
         isLazy:        {type: Boolean,default: false},
         isFocus:       {type: Boolean,default: false},
         isTdSelectBox: {type: Boolean,default: false,},

         // ################################ input events
         focus:    {type: Function,default: null},
         blur:     {type: Function,default: null},
         change:   {type: Function,default: null},
         

         // ################################ parent props 
         parentClass: {type: String, default: ''},


         // ################################ label props 
         labelClass: { type: String, default: ''},
         label:      { type: [String, Boolean], default: false}, 
         labelIcon : { type: String, default: '',},
         labelHTML:  { type: Boolean, default: false,},
         labelClick: { type: Function, default: null,},
         
         // ################################ layout
         labelRow:           { type: [Boolean, String], default: false }, 
         labelRowBreakPoint: { type: String, default: 'md' },
         labelRowTextCenter: { type: Boolean, default: false }, 
         labelRowBreakPointExists: { type: Boolean, default: false },
      },

      setup(props, context) {
         const type     = props.type;
         const attrs    = context.attrs;
         const store    = useStore();
         const lang     = store.getters['config/getLang'];

         onMounted(() => {
            const selector = `.input-wrapper [name="${props.name}"]`;
            const input    = document.querySelector(selector);
            const wrapper  = input.parentElement;
            const label    = wrapper.querySelector('label');

            // remove & add attributes from parent element
            for (let key in context.attrs) {
               if (key == 'onUpdate:modelValue') continue;
               if (key == 'parentClass') input.removeAttribute(key);
               
               if (key == 'class') wrapper.className = 'input-wrapper my-1 ' + props.parentClass;
               else if (key != 'parentClass') wrapper.removeAttribute(key);
            }

            // to watch if class has changed dynamical
            onUpdated(()=> {
               if (!isEmpty(props.labelRow) && props.labelRow !== false) wrapper.className = 'input-wrapper my-1 row align-items-center ' + props.parentClass;
            })


            // set input value with the model value
            if (props.modelValue !== null) input.value = props.modelValue;


            // update input value => method based on some attributes & input type
            if (props.isLazy || (props.isZeroEmptyInt && props.isLazyZeroEmptyInt)) 
               input.addEventListener('blur', ()=> context.emit('update:modelValue', input.value));
            else 
               input.addEventListener('input', ()=> context.emit('update:modelValue', input.value));
            

            if (label) {
               if (label.classList.contains('mb-0') || label.classList.contains('my-0')) {
                  label.classList.remove('mb-1', 'mb-2', 'mb-3', 'mb-4', 'mb-5');
               }
            }

            if (wrapper) {
               if (wrapper.classList.contains('my-0')) wrapper.classList.remove('my-1', 'my-2', 'my-3', 'my-4', 'my-5');
               if (wrapper.classList.contains('my-2')) wrapper.classList.remove('my-1', 'my-3', 'my-4', 'my-5');
               if (wrapper.classList.contains('my-3')) wrapper.classList.remove('my-1', 'my-2', 'my-4', 'my-5');
               if (wrapper.classList.contains('my-4')) wrapper.classList.remove('my-1', 'my-2', 'my-3', 'my-5');
               if (wrapper.classList.contains('my-5')) wrapper.classList.remove('my-1', 'my-2', 'my-3', 'my-4');
            }


            // layout
            if (!isEmpty(props.labelRow) && props.labelRow !== false) {
               if (isNaN(parseInt(props.labelRow))) return;

               let cols            = 12;
               let labelCol        = props.labelRow;
               let inputWrapperCol = cols - labelCol;

               let div            = document.createElement('div');
               let labelDiv       = document.createElement('div');
               div.className      = props.labelRowBreakPointExists ? `col-${inputWrapperCol}` : `col-${props.labelRowBreakPoint}-${inputWrapperCol}`;
               labelDiv.className = props.labelRowBreakPointExists ? `col-${labelCol} position-relative` : `col-${props.labelRowBreakPoint}-${labelCol} position-relative`;

               div.appendChild(input);
               labelDiv.appendChild(label);

               wrapper.appendChild(labelDiv);
               wrapper.appendChild(div);
               wrapper.classList.add('row', 'align-items-center');
               
               
               label.classList.add('col-form-label', 'd-block', 'mw-fit');
               if (props.labelRowTextCenter) label.classList.add(`mx-${props.labelRowBreakPoint}-auto`);
               label.classList.remove('mb-1', 'mb-2', 'mb-3', 'mb-4', 'mb-5', 'mb-6');
            }
         });
      }
   }
</script>


<style lang="scss" scoped>
@use '@sass/utils' as *;
@use '@sass/variables' as *;

.input-wrapper {

   select.td-select { 
      background-image: url('~@/assets/imgs/main_icons/dropdown.svg');
   }

}
</style>