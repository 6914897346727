import { createRouter, createWebHistory } from 'vue-router';
import {products}        from '@/router/router.products';
import {users}           from '@/router/router.users';
import {stocks}          from '@/router/router.stocks';
import {suppliers}       from '@/router/router.suppliers';
import {customers}       from '@/router/router.customers';
import {invoices}        from '@/router/router.invoices';
import {printInvoices}   from '@/router/router.printInvoices';
import {expenses}        from '@/router/router.expenses';


const home = [
   {
      path: '/',
      name: 'home',
      component: () => import('../views/sidebar/Home.vue'),
      meta: {
         pageTitle: 'lang_home',
      }
   },
];

const shortcuts = [
   {
      path: '/shortcuts',
      name: 'shortcuts',
      component: () => import('../views/sidebar/Shortcuts.vue'),
   },
   {
      path: '/shortcuts/users',
      name: 'users',
      meta: {
         permissionName: 'users',
      },
      component: () => import('../views/pages/Users.vue'),
   },
   {
      path: '/shortcuts/settings',
      name: 'settings',
      meta: {
         permissionName: 'settings',
      },
      component: () => import('../views/pages/Settings.vue'),
   },
   {
      path: '/shortcuts/drivers',
      name: 'drivers',
      meta: {
         permissionName: 'drivers',
      },
      component: () => import('../views/pages/Drivers.vue'),
   },
   {
      path: '/shortcuts/delegates',
      name: 'delegates',
      meta: {
         permissionName: 'delegates',
      },
      component: () => import('../views/pages/Delegates.vue'),
   },
   {
      path: '/shortcuts/users_permissions',
      name: 'users_permissions',
      component: () => import('../views/pages/UsersPermissions.vue'),
   },
   {
      path: '/shortcuts/delegates_reports',
      name: 'delegates_reports',
      component: () => import('../views/pages/DelegatesReports.vue'),
   },
   {
      path: '/shortcuts/governorate',
      name: 'governorate',
      component: () => import('../views/pages/Governorate.vue'),
   },
   {
      path: '/shortcuts/regions',
      name: 'regions',
      component: () => import('../views/pages/Regions.vue'),
   },
];


const safes = [
   {
      path: '/safe',
      name: 'safe',
      component: () => import('../views/sidebar/Safe.vue'),
   },
   {
      path: '/safe/stocks',
      name: 'stocks',
      component: () => import('../views/pages/Stocks.vue'),
   },
   {
      path: '/safe/price_difference_types',
      name: 'price_difference_types',
      component: () => import('../views/pages/PriceDifferenceTypes.vue'),
   },
   {
      path: '/safe/safes_operations',
      name: 'safes_operations',
      component: () => import('../views/pages/SafesOperations.vue'),
   },
   // {
   //    path: '/safe/deposit_money_to_safe',
   //    name: 'deposit_money_to_safe',
   //    component: () => import('../views/pages/DepositMoneyToSafe.vue'),
   // },
   // {
   //    path: '/safe/transfer_money_to_safe',
   //    name: 'transfer_money_to_safe',
   //    component: () => import('../views/pages/TransferMoneyToSafe.vue'),
   // },
   // {
   //    path: '/safe/safe_reports',
   //    name: 'safe_reports',
   //    component: () => import('../views/pages/SafeReports.vue'),
   // },
   // {
   //    path: '/safe/balance',
   //    name: 'balance',
   //    component: () => import('../views/pages/SafeMoney.vue'),
   // },
   {
      path: '/safe/profits',
      name: 'profits',
      component: () => import('../views/pages/SafeProfitsReports.vue'),
   },
];

const sales = [
   {
      path: '/sales',
      name: 'sales',
      component: () => import('../views/sidebar/Sales.vue'),
   },
   {
      path: '/sales/sales_reports/:invoice?/:currency?',
      name: 'sales_reports',
      component: () => import('../views/pages/SalesReports.vue'),
   },
   {
      path: '/sales/invoice_reports/:invoiceId?/:currency?',
      name: 'invoice_reports',
      component: () => import('../views/pages/SalesInvoiceReports.vue'),
   },
];

// const suppliers = [
//    {
//       path: '/suppliers',
//       name: 'suppliers',
//       component: () => import('../views/sidebar/Suppliers.vue'),
//    },
//    {
//       path: '/suppliers/add_supplier',
//       name: 'add_supplier',
//       component: () => import('../views/pages/AddSupplier.vue'),
//    },
//    {
//       path: '/suppliers/suppliers_payments',
//       name: 'suppliers_payments',
//       component: () => import('../views/pages/SuppliersPayments.vue'),
//    },
//    {
//       path: '/suppliers/suppliers_reports',
//       name: 'suppliers_reports',
//       component: () => import('../views/pages/SuppliersReports.vue'),
//    },
// ];

// const customers = [
//    {
//       path: '/customers',
//       name: 'customers',
//       component: () => import('../views/sidebar/Customers.vue'),
//    },
//    {
//       path: '/customers/add_customer',
//       name: 'add_customer',
//       component: () => import('../views/pages/Customers.vue'),
//    },
//    {
//       path: '/customers/customers_payments',
//       name: 'customers_payments',
//       component: () => import('../views/pages/CustomersPayments.vue'),
//    },
//    {
//       path: '/customers/customers_debts',
//       name: 'customers_debts',
//       component: () => import('../views/pages/CustomersDebts.vue'),
//    },
//    {
//       path: '/customers/customers_reports',
//       name: 'customers_reports',
//       component: () => import('../views/pages/CustomersReports.vue'),
//    },
//    {
//       path: '/customers/customers_all_debts_reports',
//       name: 'customers_all_debts_reports',
//       component: () => import('../views/pages/CustomersAllDebtsReports.vue'),
//    },
//    {
//       path: '/customers/customers_accounts',
//       name: 'customers_accounts',
//       component: () => import('../views/pages/CustomersAccounts.vue'),
//    },
//    {
//       path: '/customers/customers_type',
//       name: 'customers_type',
//       component: () => import('../views/pages/CustomersType.vue'),
//    },
// ];

const reports = [
   {
      path: '/reports',
      name: 'reports',
      component: () => import('../views/sidebar/Reports.vue'),
   },
   {
      path: '/customers/customer_detailed_reports',
      name: 'customer_detailed_reports',
      component: () => import('../views/pages/CustomerDetailedReports.vue'),
   },
   {
      path: '/purchases/purchases_reports',
      name: 'purchases_reports',
      component: () => import('../views/pages/PurchasesReports.vue'),
   },
];

const product = [
   {
      path: '/product_page/:productId',
      name: 'product_page',
      component: () => import('../views/pages/ProductPage.vue'),
   },
   {
      path: '/products',
      name: 'products',
      component: () => import('../views/sidebar/Products.vue'),
   },
   {
      path: '/products/categories',
      name: 'categories',
      component: () => import('../views/pages/Categories.vue'),
   },
   {
      path: '/products/sub_category',
      name: 'sub_category',
      component: () => import('../views/pages/SubCategory.vue'),
   },
   {
      path: '/products/add_products',
      name: 'add_products',
      component: () => import('../views/pages/AddProducts.vue'),
   },
   {
      path: '/products/products_store/:productName?',
      name: 'products_store',
      component: () => import('../views/pages/ProductsStore.vue'),
   },
   {
      path: '/products/stores',
      name: 'stores',
      component: () => import('../views/pages/Stores.vue'),
   },
   {
      path: '/products/store_reports',
      name: 'store_reports',
      component: () => import('../views/pages/StoreReports.vue'),
   },
   {
      path: '/products/corrupted_stores',
      name: 'corrupted_stores',
      component: () => import('../views/pages/CorruptedStores.vue'),
   },
   {
      path: '/products/transfer_products',
      name: 'transfer_products',
      component: () => import('../views/pages/TransferProducts.vue'),
   },
   {
      path: '/products/transfer_products_reports',
      name: 'transfer_products_reports',
      component: () => import('../views/pages/TransferProductsReports.vue'),
   },
];


const shop = [
   {
      path: '/shop',
      name: 'shop',
      component: () => import('../views/pages/ShopData.vue'),
   },
];

const backup = [
   {
      path: '/backup',
      name: 'backup',
      component: () => import('../views/pages/Backup.vue'),
   },
];

const notification = [
   {
      path: '/notifications',
      name: 'notifications',
      component: () => import('../views/pages/Notifications.vue'),
   },
];

const purchases = [
   {
      path: '/purchases',
      name: 'purchases',
      component: () => import('../views/sidebar/Purchases.vue'),
   },
   {
      path: '/purchases/purchases_reports',
      name: 'purchases_reports',
      component: () => import('../views/pages/PurchasesReports.vue'),
   },
   {
      path: '/purchases/purchases_invoice',
      name: 'purchases_invoice',
      // component: () => import('../views/pages/PurchasesInvoice.vue'),
   },
];


const print = [
   {
      path: '/print_delegates_reports',
      name: 'print_delegates_reports',
      component: () => import('../views/print/PrintDelegatesReports.vue'),
      props: route => ({
         id: route.query.id,
         from: route.query.from,
         to: route.query.to,
      }),
   },
   {
      path: '/print_sales_reports',
      name: 'print_sales_reports',
      component: () => import('../views/print/PrintSalesReports.vue'),
      props: route => ({
         from: route.query.from,
         to: route.query.to,
         invoice: route.query.invoice,
         product: route.query.product,
         currency: route.query.currency,
         type: route.query.type,
      }),
   },
   {
      path: '/print_invoice_reports',
      name: 'print_invoice_reports',
      component: () => import('../views/print/PrintInvoiceReports.vue'),
      props: route => ({
         invoice: route.query.invoice,
         customer: route.query.customer,
         currency: route.query.currency,
         type: route.query.type,
      }),
   },
   {
      path: '/print_invoice/:invoiceId?/:type?',
      name: 'print_invoice',
      component: () => import('../views/print/PrintSalesInvoice.vue'),
   },
   // {
   //    path: '/print_invoice_8cm/:invoiceId?',
   //    name: 'print_invoice_8cm',
   //    component: () => import('../views/print/PrintInvoice8cm.vue'),
   // },
   {
      path: '/print_purchase_invoice/:invoiceId?',
      name: 'print_purchase_invoice',
      component: () => import('../views/print/PrintPurchaseInvoice.vue'),
   },
   {
      path: '/print_purchase_invoices_reports',
      name: 'print_purchase_invoices_reports',
      component: () => import('../views/print/PrintPurchaseInvoicesReports.vue'),
      props: route => ({
         invoice: route.query.invoice,
         supplier: route.query.supplier,
         currency: route.query.currency,
      }),
   },
   {
      path: '/print_purchase_invoice_8cm/:invoiceId?',
      name: 'print_purchase_invoice_8cm',
      component: () => import('../views/print/PrintPurchaseInvoice8cm.vue'),
   },
   {
      path: '/print_customers_payments/:invoiceId',
      name: 'print_customers_payments',
      component: () => import('../views/print/PrintCustomersPayments.vue'),
   },
   {
      path: '/print_customers_payments_8cm/:invoiceId',
      name: 'print_customers_payments_8cm',
      component: () => import('../views/print/PrintCustomersPayments8cm.vue'),
   },
   {
      path: '/print_customers_reports/:currency',
      name: 'print_customers_reports',
      component: () => import('../views/print/PrintCustomersReports.vue'),
   },
   {
      path: '/print_customers_all_debts_reports/:type/:currency/:customer?/',
      name: 'print_customers_all_debts_reports',
      component: () => import('../views/print/PrintCustomersAllDebtsReports.vue'),
   },
   {
      path: '/print_customer_detailed_reports',
      name: 'print_customer_detailed_reports',
      component: () => import('../views/print/PrintCustomerDetailedReports.vue'),
      props: route => ({
         customer: route.query.customer,
         currency: route.query.currency,
         from: route.query.from,
         to: route.query.to,
      }),
   },
   {
      path: '/print_store_reports',
      name: 'print_store_reports',
      component: () => import('../views/print/PrintStoreReports.vue'),
      props: route => ({
         type: route.query.type,
         currency: route.query.currency,
         store: route.query.store,
         supplier: route.query.supplier,
         category: route.query.category,
         sub_category: route.query.subCategory,
      }),
   },
   {
      path: '/print_transfer_products_reports',
      name: 'print_transfer_products_reports',
      component: () => import('../views/print/PrintTransferProductsReports.vue'),
      props: route => ({
         from: route.query.from,
         to: route.query.to,
         product: route.query.product,
         type: route.query.type,
         store: route.query.store,
         corruptedStore: route.query.corrupted_store,
         category: route.query.category,
         subCategory: route.query.sub_category,
      }),
   },

   {
      path: '/print_purchase_invoice_reports',
      name: 'print_purchase_invoice_reports',
      component: () => import('../views/print/PrintPurchaseInvoiceReports.vue'),
      props: route => ({
         from: route.query.from,
         to: route.query.to,
         invoice: route.query.invoice,
         product: route.query.product,
         currency: route.query.currency,
      }),
   },

   {
      path: '/print_return_invoice/:type/:invoiceId?/',
      name: 'print_return_invoice',
      component: () => import('../views/print/PrintReturnInvoice.vue'),
   },
   {
      path: '/print_return_invoices_reports',
      name: 'print_return_invoices_reports',
      component: () => import('../views/print/PrintReturnInvoicesReports.vue'),
      props: route => ({
         invoice: route.query.invoice,
         type: route.query.type,
         currency: route.query.currency,
         supplier: route.query.supplier,
         customer: route.query.customer,
      }),
   },
   {
      path: '/print_return_invoice_reports',
      name: 'print_return_invoice_reports',
      component: () => import('../views/print/PrintReturnInvoiceReports.vue'),
      props: route => ({
         from: route.query.from,
         to: route.query.to,
         invoice: route.query.invoice,
         product: route.query.product,
         currency: route.query.currency,
         type: route.query.type,
      }),
   },
];

const routes = [
   ...home,
   ...products,
   ...users,
   ...stocks,
   ...customers,
   ...suppliers,
   ...invoices,
   ...printInvoices,
   ...expenses,

   // ...shortcuts,
   // ...print,
   // ...expenses,
   // ...safes,
   // ...sales,
   // ...suppliers,
   // ...customers,
   // ...reports,
   // ...product,
   // ...invoices,
   // ...shop,
   // ...backup,
   // ...purchases,
   // ...notification,

   {
      path: '/login',
      name: 'login',
      component: () => import('../views/log/Login.vue'),
      meta: { 
         showNav: false,
         auth: false,
         showHeader: false,
         fixedBtns: false,
      },
   },
   {
      path: '/language',
      name: 'language',
      component: () => import('../views/sidebar/Language.vue'),
      meta: {
         pageTitle: 'lang_change_language'
      }
   },
   {
      path: '/:catchAll(.*)',
      component: () => import('../views/error/404.vue'),
      meta: { 
         showNav: false,
         showHeader: false,
         showGlobalSearch: false,
         fixedBtns: false,
      }
   },
];

routes.forEach((obj, i) => {
   if (!wordExists(obj.path, 'print')) return;

   if (!obj.meta) {

      obj.meta = {
         showNav: false,
         showHeader : false,
      }

   } else {
      obj.meta.showNav    = false;
      obj.meta.ShowHeader = false;
   }
});

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   routes
});


export default router
