import {Classes} from '@/utils/classes/Classes';

const CRUDManager = Classes.getClass('CRUDManager');
CRUDManager.showDataLoader = false;

export default {

   // user permissions data
   async setPermissions(context) {
      let {[RESPONSE_DATA_NAME]: permissions, [RESPONSE_BOOLEAN_NAME]: isSuccess} = await CRUDManager.get('user_permissions_fetcher/get_modified_permissions_names');
      if (isSuccess) context.commit('intiPermissions', permissions);
   },

   async updatePermissions(context, userPermissionsData = {}) {
      let {[RESPONSE_BOOLEAN_NAME]: isUpdated} = await CRUDManager.update('user_permissions_modifier/update_permission', userPermissionsData);
      if (isUpdated) context.dispatch('setPermissions');
   },

   async updateAllPermissions(context, userPermissionsData = {}) {
      let {[RESPONSE_BOOLEAN_NAME]: isUpdated} = await CRUDManager.update('user_permissions_modifier/update_all_permissions', userPermissionsData);
      if (isUpdated) context.dispatch('setPermissions');
   },
}