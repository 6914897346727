<template>
   <div :class="[parentClass, 'input-wrapper my-1']">
      <input
         class="form-control"
         type="radio"
         :name="name"
         :value="modelValue ?? ''"
         :id="id ?? name"
         :disabled="isDisabled"

         v-bind="$attrs"

         @click="click"
      />

      <span 
         class="radioToCheckBox"
         data-radio-style-checked
         :style="radioBoxStyle"
      >
         <i class="fas fa-check"></i>
      </span>

      <label 
         v-if="label"
         :for="id ?? name" 
         class="mb-1" 
         :class="labelClass" 
         v-html="labelHTML ? label : this.$t(label) + `${labelIcon}`">
      </label>

   </div>
</template>


<script>
   import { onMounted, watch, onUpdated} from 'vue';
   import { useStore }                   from 'vuex';

   export default {
      name: 'BaseInput',

      props: {
         // ################################ input props
         type:        {type: String,default: 'radio'},
         name:        {type: String,default: ''},
         modelValue:  {type: [String, null],default: null,},
         id:          {type: [String, null],default: null,},
         isDisabled:  {type: Boolean,default: false,},

         // ################################ input events
         click: {type: Function,default: null},

         // ################################ parent props 
         parentClass: {type: String, default: ''},


         // ################################ label props 
         labelClass: { type: String, default: ''},
         label:      { type: [String, Boolean], default: false}, 
         labelIcon : { type: String, default: '',},
         labelHTML:  { type: Boolean, default: false,},

         // ################################ extra element adding if needed
         hasExtraEl:    { type: Boolean, default: false},
         extraElClass:  { type: String, default: null},
         extraElId:     { type: String, default: null},
         extraElTxt:    { type: String, default: ''},
         radioBoxStyle: { type: String, default: '',},

         
         // ################################ layout
         labelRow:           { type: [Boolean, String], default: false }, 
         labelRowBreakPoint: { type: String, default: 'md' },

      },

      setup(props, context) {
         const type     = 'radio';
         const attrs    = context.attrs;
         const store    = useStore();
         const lang     = store.getters['config/getLang'];

         onMounted(() => {
            const selector = `.input-wrapper #${props.id}`;
            const input    = document.querySelector(selector);
            const wrapper  = input.parentElement;
            const label    = wrapper.querySelector('label');

            // if type is checkbox or radio remove & add some classes (Bootstrap) & emit event when the input changed
            input.classList.remove('form-control');
            
            input.addEventListener('change', function() {
               if (this.checked) context.emit('isChecked');
            });
            
            wrapper.classList.add('form-check');
            input.classList.add('form-check-input');
            if (label) label.classList.add('form-check-label');


            // remove & add attributes from parent element
            for (let key in context.attrs) {
               if (key == 'onUpdate:modelValue') continue;
               if (key == 'parentClass') input.removeAttribute(key);
               
               if (key == 'class') wrapper.className = 'input-wrapper my-1 ' + props.parentClass;
               else if (key != 'parentClass') wrapper.removeAttribute(key);
            }

            // to watch if class has changed dynamical
            onUpdated(()=> {
               if (!isEmpty(props.labelRow) && props.labelRow !== false) wrapper.className = 'input-wrapper my-1 row align-items-center ' + props.parentClass;
            })

            // update input value => method based on some attributes & input type
            input.addEventListener('change', ()=> context.emit('update:modelValue', input.value));
            
            if (props.modelValue === input.value) {
               input.checked = true;
               input.setAttribute('checked', true);
            }


            if (label) {
               if (label.classList.contains('mb-0') || label.classList.contains('my-0')) {
                  label.classList.remove('mb-1', 'mb-2', 'mb-3', 'mb-4', 'mb-5');
               }
            }

            if (wrapper) {
               if (wrapper.classList.contains('my-0')) wrapper.classList.remove('my-1', 'my-2', 'my-3', 'my-4', 'my-5');
               if (wrapper.classList.contains('my-2')) wrapper.classList.remove('my-1', 'my-3', 'my-4', 'my-5');
               if (wrapper.classList.contains('my-3')) wrapper.classList.remove('my-1', 'my-2', 'my-4', 'my-5');
               if (wrapper.classList.contains('my-4')) wrapper.classList.remove('my-1', 'my-2', 'my-3', 'my-5');
               if (wrapper.classList.contains('my-5')) wrapper.classList.remove('my-1', 'my-2', 'my-3', 'my-4');
            }


            // if the model changed then make the right input checked 
            wrapper.style.position = 'relative';

            watch(()=> props.modelValue, (newValue)=> {
               if (newValue === input.value.trim()) input.checked = true; 
            });

            // layout
            if (!isEmpty(props.labelRow) && props.labelRow !== false) {
               if (isNaN(parseInt(props.labelRow))) return;

               let cols            = 12;
               let labelCol        = props.labelRow;
               let inputWrapperCol = cols - labelCol;

               let div            = document.createElement('div');
               let labelDiv       = document.createElement('div');
               div.className      = `col-${props.labelRowBreakPoint}-${inputWrapperCol}`;
               labelDiv.className = `col-${props.labelRowBreakPoint}-${labelCol} position-relative`;

               div.appendChild(input);
               labelDiv.appendChild(label);

               wrapper.appendChild(labelDiv);
               wrapper.appendChild(div);
               wrapper.classList.add('row', 'align-items-center');

               label.classList.add('col-form-label', 'd-block', 'mx-auto', 'mw-fit', 'text-center');
               label.classList.remove('mb-1', 'mb-2', 'mb-3', 'mb-4', 'mb-5', 'mb-6');
            }
            
            if (document.querySelector('[data-radio-style-checked]')) {
      
               document.querySelectorAll('[data-radio-style-checked]').forEach(el => {
                  let parent = el.parentElement;
                  let input  = parent.querySelector('input[type="radio"]');
                  
                  el.addEventListener('click', _=> input.click());
               });

            }

         });
      }
   }
</script>


<style lang="scss" scoped>
@use '@sass/utils' as *;
@use '@sass/variables' as *;

.input-wrapper {
   
   .radioToCheckBox {
      --bg: #{$color-gray-10};
      --text: var(--s-yellow-clr);
      --border-clr: var(--bg); 
      --transition-delay: .15s;

      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      display: grid;
      place-items: center;
      height: rem(22);
      width: rem(22);
      font-size: rem(14);
      border: solid rem(1) var(--color-gray-6);
      color: transparent;
      background-color: white;
      cursor: pointer;

      background-size: 0%;

      &::after {
         content: '';
         position: absolute;
         inset: 0;
         background-color: var(--bg);
         transform: scale(0);
         transition: var(--transition-delay) ease;
      }
      
      i { 
         position: relative;
         color: transparent; 
         z-index: 2;
      }

      & ~ label { @include br(sm) {white-space: nowrap } }
   }

   input[type='radio'] {
      opacity: 0;
      height: rem(22);
      width: rem(22);
      vertical-align: text-bottom;
      display: inline-block;
      pointer-events: none;

      &:checked ~ .radioToCheckBox { 
         transition: .15s ease, border .15s var(--transition-delay) ease;

         border-color: var(--border-clr);
         color: var(--text);

         &::after {
            transform: scale(1);
         }

         i { 
            color: inherit;
            transition: color .1s var(--transition-delay) ease;
         }
      }
   }

   // .radioToCheckBox ~ label { @include br(sm) {white-space: nowrap } }
}
</style>