import {CookiesController} from '@/utils/classes/CookiesController';
const CookiesClass = new CookiesController();

const filters = {
   setCurrency(number = 0, currency_type = 'dinar', onlyCurrency = false) {
      
      currency_type = currency_type.trim();
      number        += '';
      number        = number.trim();
      
      let lang = CookiesClass.getCookie(LANG_COOKIE_NAME);
      
      if (onlyCurrency) return currency_type == 'dollar' ? '$' : (lang == 'en' ?  'IQD' : 'د.ع');

      if (number == 0 && !isEmpty(number)) return 0;
      else if (currency_type == 'dollar') {

         if (number == '') return '$';
         else return parseFloat(number).toLocaleString('en-US') + ' $';

      } else if (currency_type == 'dinar') {
         let sign = lang == 'en' ? ' IQD' : 'د.ع' ;

         if (number == '') return sign;
         else return parseFloat(number).toLocaleString('en-US') + " " + sign;
      }
   },

   numberFormat(number, pureNumber = false) {
      number = parseFloat(number);
      number = (Math.round(number * 100) / 100);

      if (pureNumber) return number;
      else return number.toLocaleString('en-US');
   }
}

export default filters;