export const invoices = [
   {
      path: '/add_sales_invoice',
      name: 'add_sales_invoice',
      component: () => import('../views/pages/AddSalesInvoice.vue'),
      meta: {
         pageTitle: 'lang_sales_invoice',
      }
   },
   
   {
      path: '/sales_invoice_reports/:invoiceId?/:currency?',
      name: 'sales_invoice_reports',
      component: () => import('../views/pages/SalesInvoiceReports.vue'),
      meta: {
         pageTitle: 'lang_invoice_reports',
      }
   },

   {
      path: '/edit_sales_invoice/:invoiceId',
      name: 'edit_sales_invoice',
      component: () => import('../views/pages/EditSalesInvoice.vue'),
      meta: {
         pageTitle: 'lang_edit_invoice',
      }
   },
];