<template>
   <div id="fixed-btns" class="fixed-btns">
      <div>
         <router-link 
            v-if="settingsPermission" 
            to="/shortcuts/settings" 
            id="fixed_settings_btn" 
            tooltip 
            :tt-dir="$isArb() ? 'right' : 'left'" 
            :tt-text="$t('lang_program_settings')" 
            tt-fw-bold 
         >
            <i class="fa-solid fa-gear"></i>
         </router-link>

         <router-link 
            to="/notifications" 
            id="fixed_notification_btn" 
            :style="settingsPermission ? '--top: 40' : '--top: 0'" 
            tooltip 
            :tt-dir="$isArb() ? 'right' : 'left'" 
            :tt-text="$t('lang_notifications')" 
            tt-fw-bold
         >
            <i class="fa-solid fa-bell"></i>
            <span></span>
         </router-link>
      </div>
      
      <div id="product_invoice_btn_holder">

         <router-link 
            to="/invoices/pre_invoice" 
            id="fixed_add_pre_invoice_btn" 
            tooltip 
            :tt-dir="$isArb() ? 'right' : 'left'" 
            :tt-text="$t('lang_order_invoice')" 
            tt-fw-bold
         >
            <i class="fa-solid fa-plus"></i> 
            <lord-icon src="https://cdn.lordicon.com/amfpjnmb.json" trigger="hover" colors="primary:#121331,secondary:#ebe6ef,tertiary:#e4e4e4,quaternary:#ffc738,quinary:#646e78" ></lord-icon>
         </router-link>

         <router-link 
            to="/invoices/sales_invoice" 
            id="fixed_add_invoice_btn" 
            tooltip 
            :tt-dir="$isArb() ? 'right' : 'left'" 
            :tt-text="$t('lang_new_invoice')" 
            tt-fw-bold 
         >
            <i class="fa-solid fa-plus"></i>
            <lord-icon src="https://cdn.lordicon.com/cllunfud.json" trigger="hover" colors="outline:#ffffff,primary:#eee966,secondary:#ffffff"></lord-icon>
         </router-link>

         <router-link 
            v-if="productsPermission" 
            to="/products/add_products" 
            id="fixed_add_product_btn" 
            tooltip 
            :tt-dir="$isArb() ? 'right' : 'left'" 
            :tt-text="$t('lang_add_item')" 
            tt-fw-bold
         >
            <i class="fa-solid fa-plus"></i>
            <lord-icon src="https://cdn.lordicon.com/gzcbkoye.json" trigger="hover" ></lord-icon>
         </router-link>
         
         <span class="indicator"><i class="fa-solid fa-hand-point-up"></i></span>
      </div>
   </div>
</template>

<script>
import { reactive, toRefs} from 'vue'
import { useStore }  from 'vuex';


export default {
   
   setup () {
      const store = useStore();
      const permissions = store.getters['permissions/getPermissions'];

      const state = reactive({
         settingsPermission: permissions != null ? (permissions.programsSettings == 'f' ? false : true) : true,
         productsPermission: permissions != null ? (permissions.products == 'f' ? false : true) : true,
      });
   
      return {
         ...toRefs(state),
      }
   },

   created() {
      if (this.$store.state.permissions.permissions != null) {
         this.$watch(() => this.$store.state.permissions.permissions.programsSettings, (newValue) => newValue == 'f' ? this.settingsPermission = false : this.settingsPermission = true);
         this.$watch(() => this.$store.state.permissions.permissions.products, (newValue) => newValue == 'f' ? this.productsPermission = false : this.productsPermission = true);
      }
   }
}
</script>

<style lang="scss" scoped>
   @use '@sass/utils' as *;
   @use '@sass/variables' as *;
   @use '@sass/animation' as *;

   .fixed-btns {
      #product_invoice_btn_holder {
         --transition-time-func: .2s; 
         --transition-time-func-hover: .085s;
         --transition-btn-delay: .05s;

         position: static;
         z-index: 123;
         left: .5rem;
         bottom: .5rem;
         display: flex;
         justify-content: center;
         align-items: center;
         transition: .3s ease;
         
         gap: .25rem;
         width: fit-content;
         height: fit-content;
         
         > * { 
            position: fixed;
            z-index: 123;
            bottom: .5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            padding: .25rem !important;
            background: rgba( 0, 0, 0, 0.85 );
            -webkit-backdrop-filter: blur( rem(4) );
            backdrop-filter: blur( rem(4) );
            border: rem(1) solid rgba( 255, 255, 255, 0.18 );
            transition: var(--transition-time-func) ease;
         }
         .indicator { display: none }

         #fixed_add_pre_invoice_btn {
            display: none;
            transition-delay: calc(var(--transition-btn-delay) * 2);
         }
         
         #fixed_add_invoice_btn {
            left: .5rem;
            transition-delay: var(--transition-btn-delay);
         }

         #fixed_add_product_btn {
            right: .5rem;
         }
      
         lord-icon {
            width: rem(29);
            height: rem(29);
            transition: var(--transition-time-func) ease;
         }
      
         @include br(sm) {
            position: fixed;
            flex-direction: column;
            left: 0;
            bottom: 0;
            gap: 0;
            padding: 0 0 .15rem .15rem;

            &:hover {
               gap: .25rem;

               > * {
                  transform: scaleY(1) rotate(0);
                  padding: .5rem !important;
                  font-size: 1rem;
                  transition: var(--transition-time-func-hover) ease;
   
                  lord-icon {
                     width: rem(35);
                     height: rem(35);
                     transition: var(--transition-time-func-hover) ease;
                  }
               }

               .indicator i { 
                  transition: var(--transition-time-func-hover) ease;
                  text-shadow: 0 0 rem(5) var(--soft-yellow-clr);
               }
            }
            
            > * {
               position: static;
               transform: scaleY(0);
               transform-origin: bottom;
               padding: 0 !important;
               font-size: 0;
               border-width: 0;
            }

            lord-icon {
               width: rem(0);
               height: rem(0);
            }

            #fixed_add_pre_invoice_btn { display: flex}
            .indicator {
               display: block;
               padding: .1rem 1.55rem !important;
               border-width: rem(1);
               font-size: 1.2rem;
               transform: scale(1);

               i {
                  transition: var(--transition-time-func) ease;
               }
            }
         }
      }

      #fixed_notification_btn,
      #fixed_settings_btn {
         position: fixed;
         top: 0;
         right: auto;
         left: 0;
         z-index: 123;
         display: grid;
         place-items: center;
         width: rem(45);
         padding: .5rem;
         color: #000;
         i { font-size: rem(25) }
      
         @include br(sm, true) { display: none }
      
         background-color: rgba($color-primary, .5);
         backdrop-filter: blur(rem(5));
         -webkit-backdrop-filter: blur(rem(5));
      }
      
      #fixed_notification_btn {
         top: calc(var(--top) * 1px);
         border-radius: 0 0 rem(5) 0;
         
         @include br(sm, true) {
            top: 0;
            display: grid;
         }
      
         i { position: relative }
         
         span {
            position: absolute;
            right: rem(5);
            top: rem(3);
            left: auto;
            display: none;
            width: rem(11);
            height: rem(11);
            border-radius: 50%;
            background-color: #ff0000;
            pointer-events: none;
         }
      
         &.animate {
            i { animation: bounce .3s infinite linear alternate }
            span { display: block }
         }
      }
   }
</style>