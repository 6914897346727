<template>
   <div class="print_invoice_contact_details_design_2" id="print_invoice_contact_details_design_2">
      <div class="row">
         <!-- supplier  -->
         <div v-if="supplierCheck" class="col-4">
            <h3 class="title">{{ $t('lang_supplier') }}</h3>
            <div class="info-holder">
               <span class="label">{{ $t('lang_supplier_name') }}</span> :
               <span>{{ supplier.name }}</span>
            </div>
            <div class="info-holder" v-if="supplier.phone != ''">
               <span class="label">{{ $t('lang_phone') }}</span> : 
               <span>{{ supplier.phone }}</span>
            </div>
            <div class="info-holder" v-if="supplier.address != ''">
               <span class="label">{{ $t('lang_address') }}</span> : 
               <span>{{ supplier.address }}</span>
            </div>
         </div>

         <!-- customer  -->
         <div v-if="customerCheck" class="col-4">
            <h3 class="title">{{ $t('lang_client') }}</h3>
            <div class="info-holder">
               <span class="label">{{ $t('lang_customer_name') }}</span> :
               <span>{{ customer.name }}</span>
            </div>
            <div class="info-holder" v-if="customer.phone != ''">
               <span class="label">{{ $t('lang_phone') }}</span> : 
               <span>{{ customer.phone }}</span>
            </div>
            <div class="info-holder" v-if="customer.address != ''">
               <span class="label">{{ $t('lang_address') }}</span> : 
               <span>{{ customer.address }}</span>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { reactive, toRefs, onUpdated} from 'vue'

export default {
   props: {
      customer: { type: [Object, Boolean], default: false },
      supplier: { type: [Object, Boolean], default: false },
      invoice_type: String,
   },

   setup(props) {
      const state = reactive({
         customerCheck: false,
         supplierCheck: false,
      });
      
      if (props.customer !== false) {
         if (!isEmpty(props.customer)) state.customerCheck = true;
      }
      if (props.supplier !== false) {
         if (!isEmpty(props.supplier)) state.supplierCheck = true;
      }
      
      return {
         ...toRefs(state)
      }
   },

}
</script>

<style scoped lang="scss">
@use '@sass/utils' as *;

.print_invoice_contact_details_design_2 {
   .title {
      font-size: rem(24);
      margin-bottom: rem(10);
      color: var(--print-main-clr);
      font-weight: bold;
      text-transform: capitalize;
   }

   .info-holder {
      display: flex;
      gap: rem(15);
      margin-bottom: rem(5);
   }
}


</style>