import {Classes} from '@/utils/classes/Classes';

const CRUDManager = Classes.getClass('CRUDManager');
CRUDManager.showDataLoader = false;

export default {
   // shop data
   async setShop(context) {
      let {[RESPONSE_DATA_NAME]: data, [RESPONSE_BOOLEAN_NAME]: isSuccess} = await CRUDManager.get('shop_data_fetcher/get_modified_shop_data_names');
      if (isSuccess) context.commit('intiShop', data);
   },
   
   async updateShop(context, userData = {}) {
      CRUDManager.showDataLoader = true;
      let {[RESPONSE_BOOLEAN_NAME]: isUpdated} = await CRUDManager.update('shop_data_modifier/update_shop_data', userData);
      
      if (isUpdated) context.dispatch('setShop');
   },

}