<template>
   <div v-if="showPagination" class="container">
      <div id="pagination">
         <div class="pages-number">
            <span class="current-page">{{ currentPage }}</span> /
            <span class="pages">{{ numberPages }}</span>
         </div>

         <div class="actions-holder">
            <!-- previous btn -->
            <button v-if="!isMobile" type="button" class="btn-color-primary" @click="prev" tooltip tt-dir="top" :tt-text="$t('lang_previous_page')" >
               <i class="fa-solid" :class="$isArb() ? 'fa-chevron-right':'fa-chevron-left'"></i>
            </button>
            <form @submit.prevent="search">
               <base-input name="pagination_search" v-model="pageSearch" :label-btn="true" parent-class="input-group" :placeholder="$t('lang_page_num')" :is-int="true"/>
            </form>
            <!-- next btn  -->
            <button v-if="!isMobile" type="button" class="btn-color-primary" @click="next" tooltip tt-dir="top" :tt-text="$t('lang_next_page')">
               <i class="fa-solid" :class="$isArb() ? 'fa-chevron-left':'fa-chevron-right'"></i>
            </button>
         </div>
         
         <div v-if="isMobile"  class="btns">
            <!-- previous btn -->
            <button type="button" class="btn-color-primary" @click="prev">
                  <i class="fa-solid" :class="$isArb() ? 'fa-chevron-right':'fa-chevron-left'"></i>
            </button>
            <!-- next btn  -->
            <button type="button" class="btn-color-primary" @click="next">
               <i class="fa-solid" :class="$isArb() ? 'fa-chevron-left':'fa-chevron-right'"></i>
            </button>
         </div>
   
      </div>
   </div>
</template>


<script>
   import {ref, computed, watch} from 'vue';

   export default {
      name: 'pagination',
      props: {
         length: {
            type: Number,
            default: 0
         },
         show: {
            type: Number,
            default: PAGINATION_ITEMS_TO_SHOW
         },
         pageNumber: {
            type: [Number, String],
            default: 1,
         }
      },

      setup(props, context) {
         //============ get number of pages 
         let numberPages = computed(() => {
            let pages = parseInt(props.length / props.show);
            let check = props.length % props.show;
            if (check > 0) pages += 1;
            
            return pages;
         });

         //============ check if needed to show pagination 
         let showPagination = computed(() => {
            if (props.length > props.show) return true;
            else return false;
         });

         //============ send page number to get its data 
         let currentPage = ref(1);
         function getPage() {
            let data = {
               'page' : currentPage.value,
               'show' : props.show
            }

            context.emit('page-click', data);
         };

         // next page 
         function next() {
            currentPage.value = props.pageNumber;
            
            if (currentPage.value + 1 > numberPages.value) return;
            currentPage.value += 1;

            getPage();
         };

         // previous page
         function prev() {
            currentPage.value = props.pageNumber;

            if (currentPage.value - 1 <= 0) return;
            currentPage.value -= 1;
            getPage()
         };

         let pageSearch = ref('');
         function search() {
            if (!/[0-9]/g.test(pageSearch.value) || pageSearch.value > numberPages.value || pageSearch.value < 1 ) {
               pageSearch.value  = '';
               return;
            }
            
            currentPage.value = props.pageNumber;
            currentPage.value = parseInt(pageSearch.value);
            pageSearch.value  = '';
            getPage();
         };

         //============ watch when added items or deleted make sure the number current page right number 
         watch(numberPages, (newValue) => {

            if (newValue == 0) return;
            
            currentPage.value = props.pageNumber;

            if (newValue < currentPage.value) {
               currentPage.value = newValue;
               getPage();
            }
         });


         //============ check window width to see if it is in mobile width
         let isMobile = ref(false);
         function checkMobile() {

            if (window.innerWidth <= 370) isMobile.value = true;
            else isMobile.value = false;

         }

         return {
            // data 
            pageSearch,
            numberPages,
            showPagination,
            isMobile,
            currentPage,
            
            // methods 
            next,
            prev,
            search,
            getPage,
            checkMobile,
         }
      },

      created() {
         this.checkMobile();
         window.addEventListener('resize', this.checkMobile);
      }
   }
</script>


<style lang="scss" scoped>
   @use '@sass/utils' as *;
   @use '@sass/variables' as *;

   #pagination {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin: 1rem 0;

      .pages-number {
         display: flex;
         justify-content: center;
         align-items: center;
         gap: rem(7);
         font-size: rem(20);

         .current-page {
            font-weight: bold;
            color: red;
         }
      }

      .btns,
      .actions-holder {
         display: flex;
         justify-content: center;
         align-items: center;
         gap: rem(5);
         flex-wrap: wrap;
      }
   }
</style>