export default class ValidateOnlyNumbers {
   errMsg;
   pattern = /^\d+(?:\.\d+)?(?!\.)$/;
   isFloat = false;
   allowNegativeValue = false;
   isPercentage = false;

   constructor(isFloat, allowNegativeValue, isPercentage = false) {
      if (isFloat === true) {
         this.isFloat = true;
         this.pattern = /^(([0-9]+)?\.[0-9]+)$/;
      }

      if (allowNegativeValue === true) {
         this.allowNegativeValue = true;

         if (this.isFloat) this.pattern = /^(-?([0-9]+)?\.[0-9]+)$/;
         else this.pattern = /^-?\d+(?:\.\d+)?(?!\.)$/;
      }

      if (isPercentage === true) this.isPercentage = true;
   }

   validate(value) {
      if (!this.pattern.test(value.toString()) && value != '' && this.isPercentage === false) return this.setErr();
      else if (this.isPercentage === true && value != '') return this.#validateOnlyPercentageNumbers(value);
      else return true;
   }

   #validateOnlyPercentageNumbers(value) {
      value = value.trim();
      if (this.pattern.test(value.toString()) && value >= 0 && value <= 100 ) return true;

      if (!this.pattern.test(value.toString())) return this.setErr();
      if (value < 0 || value > 100) this.errMsg = ['lang_percentage_error']; 

      return this.errMsg;
   }

   setErr() {
      if (this.isFloat) this.errMsg = ['lang_only_float_numbers'];
      else this.errMsg = ['lang_only_numbers'];

      return this.errMsg;
   }
}