const print = [
   {
      path: '/print_transfer_products_reports',
      name: 'print_transfer_products_reports',
      component: () => import('../views/print/PrintTransferProductsReports.vue'),
      props: route => ({
         from: route.query.from,
         to: route.query.to,
         product: route.query.product,
         type: route.query.type,
         store: route.query.store,
         category: route.query.category,
      }),
   },

   {
      path: '/print_store_reports',
      name: 'print_store_reports',
      component: () => import('../views/print/PrintStoreReports.vue'),
      props: route => ({
         type: route.query.type,
         currency: route.query.currency,
         storeId: route.query.storeId,
         supplierId: route.query.supplierId,
         categoryId: route.query.categoryId,
      }),
   },
];

export const products = [
   {
      path: '/categories',
      name: 'categories',
      component: () => import('../views/pages/Categories.vue'),
      meta: {
         pageTitle: 'lang_categories',
      }
   },
   {
      path: '/stores',
      name: 'stores',
      component: () => import('../views/pages/Stores.vue'),
      meta: {
         pageTitle: 'lang_stores',
      }
   },
   {
      path: '/add_products',
      name: 'add_products',
      component: () => import('../views/pages/AddProducts.vue'),
      meta: {
         pageTitle: 'lang_add_item',
      }
   },
   {
      path: '/products',
      name: 'products',
      component: () => import('../views/pages/ProductsStore.vue'),
      meta: {
         pageTitle: 'lang_show_items',
      }
   },
   {
      path: '/transfer_products',
      name: 'transfer_products',
      component: () => import('../views/pages/TransferProducts.vue'),
      meta: {
         pageTitle: 'lang_transfer_products_between_stores',
      }
   },
   {
      path: '/transfer_products_reports',
      name: 'transfer_products_reports',
      component: () => import('../views/pages/TransferProductsReports.vue'),
      meta: {
         pageTitle: 'lang_transfer_products_reports',
      }
   },
   {
      path: '/store_reports',
      name: 'store_reports',
      component: () => import('../views/pages/StoreReports.vue'),
      meta: {
         pageTitle: 'lang_store_reports',
      }
   },

   ...print,
];