export const customers = [
   {
      path: '/customers',
      name: 'customers',
      component: () => import('../views/pages/Customers.vue'),
      meta: {
         pageTitle: 'lang_customers_data',
      }
   },
   {
      path: '/sponsors',
      name: 'sponsors',
      component: () => import('../views/pages/Sponsors.vue'),
      meta: {
         pageTitle: 'lang_sponsors',
      }
   },
   {
      path: '/customer_outer_debt',
      name: 'customer_outer_debt',
      component: () => import('../views/pages/CustomerOuterDebt.vue'),
      meta: {
         pageTitle: 'lang_outer_debt',
      }
   },
];