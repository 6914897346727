import {CRUDManager}          from '@/utils/classes/CRUDManager';
import {Modal}                from '@/utils/classes/Modal';
import {Notification}         from '@/utils/classes/Notification';
import {NotificationsChecker} from '@/utils/classes/NotificationsChecker';
import {CookiesController}    from '@/utils/classes/CookiesController';
import {DOMModifier}          from '@/utils/classes/DOMModifier';

const classMap = {
   CRUDManager,
   Modal,
   Notification,
   NotificationsChecker,
   CookiesController,
   DOMModifier,
};


export default classMap;