import {Classes} from '@/utils/classes/Classes';

const CRUDManager = Classes.getClass('CRUDManager');
CRUDManager.showDataLoader = false;

export default {
   // user settings data
   async setSettings(context) {
      let {[RESPONSE_DATA_NAME]: data, [RESPONSE_BOOLEAN_NAME]: isSuccess} = await CRUDManager.get('user_settings_fetcher/get_modified_settings_names');
      
      if (isSuccess) context.commit('intiSettings', data);
   },

   async updateSettings(context, userSettingsData = {}) {
      let {[RESPONSE_BOOLEAN_NAME]: isUpdated} = await CRUDManager.update('UserSettingsModifier/update_settings', userSettingsData);

      if (!isUpdated) return false;

      context.dispatch('setSettings');
      return true;
   },
}