import i18n from "@/language/i18n";
const t = i18n.global.t;

import { DOMModifier } from "@/utils/classes/DOMModifier";

const DOMModifierClass = new DOMModifier();

export default function globalJs() {
   // ▬▬▬▬▬▬▬▬ cancel autocomplete in input
   document.querySelectorAll('input').forEach(input => {
      if (input.getAttribute('autocomplete') == 'off') return;
      input.setAttribute('autocomplete', 'off');
   });


   // ▬▬▬▬▬▬▬▬ tooltip
   const tooltip = document.querySelector('[data-tooltip]');

   if (document.querySelector('[tooltip-overflow]')) {
      document.querySelectorAll('[tooltip-overflow]').forEach(element => {
         element.onmousemove = function(e) {
            let el = this;
            if (!el.hasAttribute('tooltip-overflow')) return;
            
            tooltip.classList.remove('d-none');
            tooltip.style.transition = 'opacity .3s .05s ease';
      
            let y             = el.getBoundingClientRect().top + document.documentElement.scrollTop;
            let x             = el.getBoundingClientRect().left + document.documentElement.scrollLeft;
            let ttWidth       = tooltip.getBoundingClientRect().width;
            let ttHeight      = tooltip.getBoundingClientRect().height;
            let text          = el.getAttribute('tt-text').trim();
            let direction     = el.getAttribute('tt-dir').trim();
      
            let isDark        = el.hasAttribute('tt-dark');
            let isBold        = el.hasAttribute('tt-fw-bold');
            let bgClr         = el.getAttribute('tt-bg-clr');
            let textClr       = el.getAttribute('tt-text-clr');
            let hasShadow     = el.hasAttribute('tt-no-shadow');
            let fs            = el.getAttribute('tt-fs');
            let windowWidth   = window.innerWidth;
            let elWidth       = el.getBoundingClientRect().width;
            let elHeight      = el.getBoundingClientRect().height;
            let tooltipMargin = 8;

            tooltip.innerHTML = t(text);

            if (direction == 'left') {
               tooltip.style.left      = `${x  - tooltipMargin + ttWidth * -1}px`;
               tooltip.style.top       = `${y + (elHeight / 2)}px`;
               tooltip.style.right     = `auto`;
               tooltip.style.bottom    = `auto`;
               tooltip.style.opacity   = `1`;
               tooltip.style.transform = `translate(0%, -50%)`;
            }
            
            if (direction == 'right') {
               tooltip.style.right     = `${(windowWidth - x - elWidth - ttWidth - tooltipMargin)}px`;
               tooltip.style.top       = `${y + (elHeight / 2)}px`;
               tooltip.style.left      = `auto`;
               tooltip.style.bottom    = `auto`;
               tooltip.style.opacity   = `1`;
               tooltip.style.transform = `translate(0%, -50%)`;
            }
      
            if (direction == 'top') {
               tooltip.style.top       = `${(y - tooltipMargin )}px`;
               tooltip.style.left      = `${(x + (elWidth / 2))}px`;
               tooltip.style.right     = `auto`;
               tooltip.style.bottom    = `auto`;
               tooltip.style.opacity   = `1`;
               tooltip.style.transform = `translate(-50%, -100%)`;
            }
      
            if (direction == 'bottom') {
               tooltip.style.top       = `${y + elHeight + tooltipMargin}px`;
               tooltip.style.left      = `${x + (elWidth / 2)}px`;
               tooltip.style.right     = `auto`;
               tooltip.style.bottom    = `auto`;
               tooltip.style.opacity   = `1`;
               tooltip.style.transform = `translate(-50%, 0%)`;
            }
      
            if (el.hasAttribute('tt-bg-clr')) tooltip.style.backgroundColor = bgClr;
      
            if (el.hasAttribute('tt-text-clr')) tooltip.style.color = textClr;
      
            if (el.hasAttribute('tt-fs')) tooltip.style.fontSize = fs;
            else tooltip.style.fontSize = '1rem';
      
            if (hasShadow) tooltip.classList.remove('remove-shadow');
      
            if (isDark) {
               tooltip.classList.add('dark');
               tooltip.classList.remove('light');
            } else {
               tooltip.classList.add('light');
               tooltip.classList.remove('dark');
            }
      
            if (isBold) tooltip.style.fontWeight = 'bold';
         }

         element.onmouseleave = function(e) {
            if (!e.target.hasAttribute('tooltip-overflow')) return;

            if (!tooltip.classList.contains('d-none')) tooltip.classList.add('d-none');

            tooltip.style.transition = 'none';
            tooltip.style.left       = 'auto';
            tooltip.style.right      = 'auto';
            tooltip.style.top        = 'auto';
            tooltip.style.bottom     = 'auto';
            tooltip.style.opacity    = 0;
         };
      });

      document.onmousemove = function(e) {
         let el     = e.target;
         let isHTML = el instanceof HTMLElement;

         if (!isHTML) return;

         if (
            !DOMModifierClass.findParent({element: el, parentSelector: '[tooltip-overflow]'})
            &&
            !DOMModifierClass.findParent({element: el, parentSelector: '[vue-tooltip-directive]'})
         ) {
            if (!tooltip.classList.contains('d-none')) tooltip.classList.add('d-none');
         }
      };
   }

   // ▬▬▬▬▬▬▬▬ make hover to moving icons when its hovered on his parent
   if (document.querySelector('lord-icon')) {
      
      let movingIcons = document.querySelectorAll('lord-icon');
      const mouseoverEvent = new Event('mouseenter');

      movingIcons.forEach(icon => {
         let parent = icon.parentElement;

         parent.onmouseover = () => icon.dispatchEvent(mouseoverEvent);
      })

   }

   // ▬▬▬▬▬▬▬▬ not focusing with tab on button if has class disable-btn
   if (document.querySelector('.disable-btn')) {
      document.querySelectorAll('.disable-btn').forEach(btn => {
         btn.setAttribute('tabindex', '-1');
      });
   }

   
}