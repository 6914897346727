export const stocks = [
   {
      path: '/stocks',
      name: 'stocks',
      component: () => import('../views/pages/Stocks.vue'),
      meta: {
         pageTitle: 'lang_stocks',
      }
   },
   {
      path: '/branches_profit_percents',
      name: 'branches_profit_percents',
      component: () => import('../views/pages/BranchesProfitPercents.vue'),
      meta: {
         pageTitle: 'lang_branches_profit_percents',
      }
   },
   {
      path: '/deposit_to_safe',
      name: 'deposit_to_safe',
      component: () => import('../views/pages/DepositToSafe.vue'),
      meta: {
         pageTitle: 'lang_deposit_money_to_bank',
      }
   },
   {
      path: '/withdraw_from_safe',
      name: 'withdraw_from_safe',
      component: () => import('../views/pages/WithdrawFromSafe.vue'),
      meta: {
         pageTitle: 'lang_take_money_from_bank',
      }
   },
   {
      path: '/transfer_between_safes',
      name: 'transfer_between_safes',
      component: () => import('../views/pages/TransferBetweenSafes.vue'),
      meta: {
         pageTitle: 'lang_transfer_money_between_banks',
      }
   },
   {
      path: '/safe_balance',
      name: 'safe_balance',
      component: () => import('../views/pages/SafeBalance.vue'),
      meta: {
         pageTitle: 'lang_balance_now',
      }
   },
   {
      path: '/safe_records_reports',
      name: 'safe_records_reports',
      component: () => import('../views/pages/SafeRecordsReports.vue'),
      meta: { 
         pageTitle: 'lang_bank_reports'
      },
   },
   {
      path: '/print_safe_records_reports',
      name: 'print_safe_records_reports',
      component: () => import('../views/print/PrintSafeRecordsReports.vue'),
      props: route => ({
         page: route.query.page,
         type: route.query.type,
         currency: route.query.currency,
         date_from: route.query.date_from,
         date_to: route.query.date_to,
      }),
   },
   {
      path: '/safe_profits_reports',
      name: 'safe_profits_reports',
      component: () => import('../views/pages/SafeProfitsReports.vue'),
      meta: { 
         pageTitle: 'lang_profits'
      },
   },
];