

export class DOMModifier {

   findParent(params = {}) {

      while (params.element) {
         if (params.element.matches(params.parentSelector)) {
            if (params.returnParent) return params.element;
            else return true
         }
         params.element = params.element.parentElement;
      }

      return false;
   }

   removeElementWithoutItsChildren(elementSelector, grandParentNode = document) {
      let parentElement = grandParentNode.querySelector(elementSelector);
   
      while (parentElement.firstElementChild) {
         parentElement.parentNode.insertBefore(parentElement.firstElementChild, parentElement);
      }
   
      parentElement.remove();
   }

   /* 
   options = {
      clonedElementSelector: '',
      newElementId: 'id ?', 
      inputValues: {
         [input name ?]: [input value ?]
      } 
      removeElementsSelectorWithChild: ['selector ?'], (optional)
      removeElementsSelectorWithoutChild: ['selector ?'], (optional)
      elementsHTMLData: [
         {selector: '?', html: '?' }
      ]  (optional)
   }
   */

   createClonedEditableModelInputs(options = {}) {
      const clonedElement = document.querySelector(options.clonedElementSelector).cloneNode(true);
      clonedElement.classList.remove('d-none');
   
      const div      = document.createElement('div');
      const labels   = clonedElement.querySelectorAll('label');
      const buttons  = clonedElement.querySelectorAll('button');
      let   inputs   = clonedElement.querySelectorAll('input, select, textarea');
   
      
      // remove error messages
      this.#removeErrorMsgs(clonedElement);
   
      // change input name & id 
      this.#changeInputAttrs(inputs);
      
      // chang label for make it connect with new input id 
      this.#changeLabelAttrs(labels);
   
      // delete all btn if exists
      buttons.forEach(btn => btn.remove());
   
      // remove some elements if dose not need to show in edit data
      if (!isEmpty(options.removeElementsSelectorWithChild)) {
         options.removeElementsSelectorWithChild.push('.empty-save-btns');
         options.removeElementsSelectorWithChild.forEach(element => {
            let deletedElements = clonedElement.querySelectorAll(element);
            deletedElements.forEach((delEl) => delEl.remove());
         });
      }
   
      // remove some elements if dose not need to show in edit data without removing his children
      if (!isEmpty(options.removeElementsSelectorWithoutChild)) {
         options.removeElementsSelectorWithoutChild.forEach(element => {
            let deletedElements = clonedElement.querySelectorAll(element);
            deletedElements.forEach(() => this.removeElementWithoutItsChildren(element, clonedElement));
         });
      }

      // change html of specific elements
      if (!isEmpty(options.elementsHTMLData)) {
         options.elementsHTMLData.forEach(data => {
            clonedElement.querySelector(data.selector).innerHTML = data.html;
         });
      }
   
      div.innerHTML = clonedElement.innerHTML;
      div.id        = options.newElementId;
      
      // insert values
      inputs = div.querySelectorAll('input, select, textarea');
      this.#insertInputValues(inputs, options.inputValues);
      
      return div;
   }

   #removeErrorMsgs(element) {
      if (element.querySelectorAll('.' + INPUT_ERROR_MSG_REVELER_CLASS)) {
         let errorMsgs     = element.querySelectorAll('.' + INPUT_ERROR_MSG_REVELER_CLASS);
         let errorElements = element.querySelectorAll('.' + INPUT_ERROR_CLASS);
   
         errorMsgs.forEach(err => err.remove());
         errorElements.forEach(errEl => errEl.classList.remove(INPUT_ERROR_CLASS));
      }
   }

   #changeInputAttrs(inputs) {
      inputs.forEach(input => {
         input.name  = input.name + INPUT_EDIT_NAME_PREFIX;
         input.id    = input.id + INPUT_EDIT_NAME_PREFIX;
      });
      
   }

   #changeLabelAttrs(labels) {
      labels.forEach(label => {
         let labelFor = label.getAttribute('for') + INPUT_EDIT_NAME_PREFIX;
         label.setAttribute('for', labelFor);
      });
   }

   #insertInputValues(inputs, inputValues) {
      if (!isEmpty(inputValues)) {
         inputs.forEach(input => {
   
            if (input.name in inputValues) {
   
               if (input.type == 'radio') {
                  if (input.value == inputValues[input.name]) {
                     input.checked = true; 
                     input.setAttribute('checked', true); 
                  } else {
                     input.checked = false;
                     input.removeAttribute('checked');
                  }
   
               } else if (input.type == 'checkbox') {
   
                  if (inputValues[input.name] == ACTIVE_IN_DB || inputValues[input.name] === true) {
                     input.value   = ACTIVE_IN_DB;
                     input.checked = true;
                     input.setAttribute('checked', true); 
                  } else {
                     input.value   = NOT_ACTIVE_IN_DB;
                     input.checked = false; 
                     input.removeAttribute('checked'); 
                  }
   
               } else input.value = inputValues[input.name];

            }
         });
      }
   }

}