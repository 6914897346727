export const printInvoices = [
   {
      path: '/print_sales_invoice/:invoiceId?',
      name: 'print_sales_invoice',
      component: () => import('../views/print/PrintSalesInvoice.vue'),
   },

   {
      path: '/print_sales_invoice_8cm/:invoiceId?',
      name: 'print_sales_invoice_8cm',
      component: () => import('../views/print/PrintSalesInvoice8cm.vue'),
   },
];