import i18n   from "@/language/i18n";
import store  from '@/store/store';

const t = i18n.global.t;

export class Notification {
   #lang;
   #defaultTimer;
   #defaultPosition;
   #defaultFontSize;

   #stylingClassName  = 'colored-toast';
   #toastAnimationIn  = 'animate__fadeInDown animate__faster';
   #toastAnimationOut = 'animate__fadeOut';
   #animationIn       = 'animate__bounceIn';
   #animationOut      = 'animate__zoomOut';

   constructor(defaultTimer = 1500, defaultPosition = 'center', defaultFontSize = 'fs-20' ) {
      this.#setLang();
      this.#defaultTimer    = defaultTimer;
      this.#defaultPosition = defaultPosition;
      this.#defaultFontSize = defaultFontSize;
   }

   success(options = {}) {
      this.#setLang();
      let width = this.#setWidthAndPosition(options);

      Swal.fire({
         toast: options.toast ?? false,
         icon: 'success',
         position: options.position ?? (options.toast ? (this.#lang != 'en' ? 'top-start' : 'top-end') : this.#defaultPosition),
         iconColor: options.toast ? 'white' : '#6abf40',
         customClass: {
            popup: `${this.#stylingClassName}`,
            title: 'text-center border-0',
         },
         
         showClass: {
            popup: `animate__animated ${(options.toast ?? false) ? this.#toastAnimationIn : this.#animationIn}`,
         },
         
         hideClass: {
            popup: `animate__animated ${(options.toast ?? false) ? this.#toastAnimationOut : this.#animationOut}`,
         },

         title: `<span class="txt-cap mx-2 d-inline-block ${options.noIcon ? 'text-color-success' : ''} ${options.fontSize ?? this.#defaultFontSize}">${t(options.msg ?? 'lang_updated')}</span>`,
         showConfirmButton: options.showConfirmBtn ?? false,
         timer: options.showConfirmBtn ? false : options.time ?? (options.toast ? this.#defaultTimer : this.#defaultTimer - 200),
         width: options.toast ? width : undefined,
         didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
         },
         timerProgressBar: options.progress ?? true,
      })
   }

   warning(options = {}) {
      this.#setLang();
      let width = this.#setWidthAndPosition(options);
   
      Swal.fire({
         toast: options.toast ?? false,
         icon: 'warning',
         position: options.position ?? (options.toast ? (this.#lang != 'en' ? 'top-start' : 'top-end') : this.#defaultPosition),
         customClass: {
            popup: this.#stylingClassName,
            title: 'text-center border-0 p-0',
         },

         showClass: {
            popup: `animate__animated ${(options.toast ?? false) ? this.#toastAnimationIn : this.#animationIn}`,
         },
         
         hideClass: {
            popup: `animate__animated ${(options.toast ?? false) ? this.#toastAnimationOut : this.#animationOut}`,
         },

         iconColor: options.toast ? 'white' : '#eab308',
         title: `<span class="txt-cap mx-2 d-inline-block ${options.noIcon ? 'text-warning' : ''} ${options.fontSize ?? this.#defaultFontSize}">${t(options.msg ?? 'lang_updated')}</span>`,
         showConfirmButton: options.showConfirmBtn ?? false,
         timer: options.showConfirmBtn ? false : options.time ?? (options.toast ? this.#defaultTimer : this.#defaultTimer - 200),
         width: options.toast ? width : undefined,
         didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
         },
         timerProgressBar: options.progress ?? true,
      });

   }

   #setLang() {
      this.#lang  = store.getters['config/getLang'];
   }

   #setWidthAndPosition(options) {
      let width = undefined;

      if (window.innerWidth <= 767) {
         options.position = options.toast ? 'top' : 'center';
         width  = '60%';

         if (window.innerWidth <= 400) width  = '100%';
      } else width = undefined;

      return width;
      
   }



}