<template>
   <div class="print_footer_design_1" id="print_footer_design_1" :style="`background-image: url('${require('@shopImgs/print_bottom.jpg')}') !important`">
      <div class="info" :class="$isArb() ?'': 'align-items-end w-100'">
         <div v-if="shopPhone" class="contact">
            <i class="fa-solid fa-phone"></i>
            <span>{{ shopPhone  }}</span>
         </div>
   
         <div v-if="shopAddress" class="contact">
            <i class="fa-solid fa-location-dot"></i>
            <span>{{ shopAddress }}</span>
         </div>
   
         <div v-if="shopSecondAddress" class="contact">
            <i class="fa-solid fa-location-dot"></i>
            <span>{{ shopSecondAddress  }}</span>
         </div>
      </div>

      <div class="social" :class="$isArb() ?'': 'align-items-end'" v-if="shopFacebook || shopInstagram || shopTelegram || shopWhatsapp">
         <div class="social_row">
            <div v-if="shopFacebook">
               <i class="fa-brands fa-facebook"></i>
               <span>{{ shopFacebook }}</span>
            </div>
            <div v-if="shopInstagram">
               <i class="fa-brands fa-instagram"></i>
               <span>{{ shopInstagram }}</span>
            </div>
         </div>
         <div class="social_row">
            <div v-if="shopWhatsapp">
               <i class="fa-brands fa-whatsapp"></i>
               <span>{{ shopWhatsapp }}</span>
            </div>
            <div v-if="shopTelegram">
               <i class="fa-brands fa-telegram"></i>
               <span>{{ shopTelegram }}</span>
            </div>
         </div>
      </div>

      <p v-if="shopNote" class="note" :class="$isArb() ?'': 'rtl'">
         {{ shopNote }}
      </p>
   </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

export default {
   setup () {
      const store = useStore();
      const shop  = store.getters['shop/getShop'];

      const state = reactive({
         shopPhone: shop.phone || false,
         shopAddress: shop.address || false,
         shopSecondAddress: shop.secondAddress || false,
         shopFacebook: shop.facebookLink || false,
         shopInstagram: shop.instagramLink || false,
         shopTelegram: shop.telegramLink || false,
         shopWhatsapp: shop.whatsappLink || false,
         shopNote: shop.note || false,
      });

      return {
         ...toRefs(state),
      }
   }
}
</script>

<style lang="scss" scoped>
@use '@sass/utils' as *;

.print_footer_design_1 {
   background-size: cover !important;
   background-color: transparent !important;
   background-repeat: no-repeat !important;
   background-position: center !important;
   padding: 1rem;
   margin-top: auto;
   font-size: 1rem;
   color: #fff !important;

   .info {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: rem(5);
      margin-bottom: rem(10);
      width: 70%;
   }

   .social {
      > * {
         display: flex;
         align-items: center;
         gap: rem(15);
      }
   }
}
</style>