<template>
   <div class="empty-save-btns">
      <button @click="saveBtnClick" type="submit" class="btn-color-s-success-bordered-shadow txt-cap">
         <span>
            {{ $t(saveBtnText) }}
            <i class='fas fa-plus mx-2'></i>
         </span>
      </button>
      <button type="button" class="btn-color-gray-6-bordered-shadow txt-cap" data-empty-fields>
         <span>
            {{$t('lang_empty_fields')}}
            <i class="fas fa-broom mx-2"></i> 
         </span>
      </button>
   </div>
</template>

<script>
import { onMounted } from 'vue';

export default {
   props: {
      saveBtnText:          {type: String, default: 'lang_add' },
      saveBtnClick:         {type: Function, default: null },
      emptyBtnNotSelectors: {type: Object, default: [] },
   },

   setup (props) {
      
      onMounted(() => {
         document.querySelector('[data-empty-fields]').addEventListener('click', ()=> {
            document.querySelectorAll('input,textarea', ).forEach(input => {

               let checkSelectors = false;
               props.emptyBtnNotSelectors.forEach(selector => {
                  if (document.querySelector(selector) == input) checkSelectors = true;
               })

               if (checkSelectors) return;

               if (input.hasAttribute('type')) {
                  if (input.getAttribute('type').trim() == 'radio' || input.getAttribute('type').trim() == 'checkbox') return;
               }

               if (input.hasAttribute('data-default-val-zero')) {
                  input.value = 0;
                  return;
               }

               if (input.hasAttribute('data-show-phone-count')) {
                  input.parentElement.querySelector('span').innerHTML = input.getAttribute('maxlength');
               }

               input.value = ''
            });
         })
      })

      return {}
   }
}
</script>

<style lang="scss" scoped>
@use '@sass/utils' as *;
@use '@sass/variables' as *;

   .empty-save-btns {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: rem(10);
   }


</style>