import i18n   from "@/language/i18n";
import store  from '@/store/store';
import { Notification } from "@/utils/classes/Notification";

const t = i18n.global.t;

export class NotificationsChecker {

   hasNotification() {
      const NotificationClass = new Notification();

      if (store.getters['notifications/getHasNotification']) {

         NotificationClass.warning({
            msg: 'lang_you_have_new_notifications',
            time: 3000,
            position: 'top',
            toast: true,
         });

         setTimeout(() => {
            if (document.querySelector('#fixed_notification_btn')) document.querySelector('#fixed_notification_btn').classList.add('animate');
         });
         
      } else {
         setTimeout(() => {
            if (document.querySelector('#fixed_notification_btn')) document.querySelector('#fixed_notification_btn').classList.remove('animate');
         });
      }

      if (store.getters['notifications/getHasSoundEffect'] == 'on') {
         let audio = new Audio(require('@/assets/sound/' + NOTIFICATION_SOUND_NAME));
         audio.play();
      }
   }
}