<template>
   <div class="print_header_design_2" id="print_header_design_2">
      <div class="info" :class="$isArb() ? '': 'flex-row-reverse'">
         <!-- invoice  -->
         <div v-if="invoiceNumber" class="info-label">
            <span class="label">{{ $t('lang_invoice_number') }}</span>
            <span>{{ invoiceNumber }}</span>
         </div>

         <!-- phone  -->
         <div v-if="shopPhone" class="info-label">
            <span class="label">{{ $t('lang_phone')}}</span> : 
            <span>{{ shopPhone }}</span>
         </div>

         <!-- time  -->
         <div class="info-label">
            <span class="label" >{{$t('lang_time')}}</span> :
            <span>{{ time }}</span>
         </div>
   
         <!-- date  -->
         <div class="info-label">
            <span class="label" >{{$t('lang_date')}}</span> :
            <span>{{ date }}</span>
         </div>
      </div>

      <p class="desc">{{ titleMsg }}</p>

      <div class="logo-wrapper">
         <div class="logo">
            <h2 class="title">{{ shopName }}</h2>
            <img :src="`${URL_PATH + UPLOADS_IMG_FOLDER_NAME}shop/${shopImg}`" class="rounded-img-100 no-img-loader" alt="Shop Logo">
         </div>
         <div class="social" v-if="shopFacebook || shopInstagram || shopTelegram || shopWhatsapp">
            <div class="social_row">
               <div v-if="shopFacebook">
                  <i class="fa-brands fa-facebook"></i>
                  <span>{{ shopFacebook }}</span>
               </div>
               <div v-if="shopInstagram">
                  <i class="fa-brands fa-instagram"></i>
                  <span>{{ shopInstagram }}</span>
               </div>
            </div>
            <div class="social_row">
               <div v-if="shopWhatsapp">
                  <i class="fa-brands fa-whatsapp"></i>
                  <span>{{ shopWhatsapp }}</span>
               </div>
               <div v-if="shopTelegram">
                  <i class="fa-brands fa-telegram"></i>
                  <span>{{ shopTelegram }}</span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { reactive, toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n';

export default {
   props: {
      currency: String,
      invoiceNumber: {type: [String, Boolean],default: false},
      title:         {type: [String, Boolean],default: false},
      date:          {type: String,default: getDate()},
      time:          {type: String,default: getTime()}
   },
   setup (props) {
      const store = useStore();
      const { t } = useI18n();
      const shop  = store.getters['shop/getShop'];


      const state = reactive({
         shopName: shop.name || false,
         shopImg: shop.image || false,
         shopPhone: shop.phone || false,
         shopFacebook: shop.facebookLink || false,
         shopInstagram: shop.instagramLink || false,
         shopTelegram: shop.telegramLink || false,
         shopWhatsapp: shop.whatsappLink || false,

         URL_PATH,
         UPLOADS_IMG_FOLDER_NAME,
      });

      const currencyName = computed(()=> {
         if (props.currency == 'dollar') return 'lang_in_dollar';
         else return 'lang_in_dinar';
      });

      const titleMsg = computed(()=> {
         if (props.title == false) return shop.description;
         else return t(props.title);
      });
   
      return {
         ...toRefs(state),

         currencyName,
         titleMsg,
      }
   }
}
</script>


<style lang="scss" scoped>
@use '@sass/utils' as *;

.print_header_design_2 {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: rem(20);
   min-height: rem(150);
   height: fit-content;

   .desc {
      padding: rem(20) 0;
      align-self: flex-start;
      text-transform: capitalize;
      font-size: rem(25);
   }

   .wrapper-logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: rem(5);
   }

   .logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }
}
</style>