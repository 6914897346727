export class CookiesController {

   getCookie(name) {
      if (document.cookie == '') return null;
   
      let cookies      = document.cookie.split(';');
      let cookiesName  = [];
      let cookiesValue = [];
   
      cookies.forEach(cookie => {
         cookiesName.push(cookie.split('=')[0].trim());
         cookiesValue.push(cookie.split('=')[1].trim());
      });
   
      if (cookiesName.includes(name)) {
         let index = cookiesName.indexOf(name);
         return cookiesValue[index];
      } 
      
      return null;
   }

   setCookie(name, value, expirationNumber, expirationType = 'year') {
      let expirationDate = new Date();
   
      if (expirationType.trim().toLocaleLowerCase() == 'year') {
         expirationDate.setFullYear(expirationDate.getFullYear() + expirationNumber);
   
      } else if (expirationType.trim().toLocaleLowerCase() == 'day') {
         expirationDate.setDate(expirationDate.getDate() + expirationNumber);
   
      } else if (expirationType.trim().toLocaleLowerCase() == 'hour') {
         expirationDate.setHours(expirationDate.getHours() + expirationNumber);
   
      } else if (expirationType.trim().toLocaleLowerCase() == 'minute') {
         expirationDate.setMinutes(expirationDate.getMinutes() + expirationNumber);
         
      } else if (expirationType.trim().toLocaleLowerCase() == 'second') {
         expirationDate.setSeconds(expirationDate.getSeconds() + expirationNumber);
      }
   
      let cookieString = name + '=' + encodeURIComponent(value) + '; expires=' + expirationDate.toUTCString() + '; path=/';
      document.cookie = cookieString;
   }
}