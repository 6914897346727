import {createI18n} from 'vue-i18n';
import en from './langs/en.json';
import ar from './langs/ar.json';
import ku from './langs/ku.json';
import {CookiesController} from '@/utils/classes/CookiesController';

const CookiesClass = new CookiesController();

const i18n = createI18n({
   legacy: false,
   locale: CookiesClass.getCookie(LANG_COOKIE_NAME) || DEFAULT_LANG,
   fallbackLocale: DEFAULT_LANG,
   messages: {
      en,
      ar,
      ku,
   }
});


export default i18n;