<template>
   <div class="w-fit d-inline-block rounded-2" :class="[(btnCenter ?? true) ? 'mx-auto' : '', isPrintBtn || isSearchBtn ? 'align-self-end' : '' ]">
      <button
         v-if="!isPrintBtn && !isSearchBtn && !isSubmitBtn"
         class="btn txt-cap flex-center gap-2"
         :class="mainBtnBg ,[btnSm ?? false ? 'btn-sm' : ''], btnClass" 
         @click="showConfirmation"
         :data-btn-text="$t(btnTxt || '')"
         > 
         
         {{ $t(mainBtnTxt) }}
         <span v-if="mainBtnIcon" v-html="mainBtnIcon" :class="btnIconFs != undefined ? `fs-${btnIconFs}` : 'fs-13'"></span>
      </button>

      <!-- submit btn  -->
      <button v-if="isSubmitBtn && !isPrintBtn && !isSearchBtn" @click="click" type="button" :id="submitBtnId" :class="[submitBtnClass, btnClass]">
         <span>{{$t(btnTxt || 'lang_save')}}</span>
         <lord-icon  src="https://cdn.lordicon.com/kjwoqegd.json" trigger="hover" stroke="bold" colors="primary:#ffffff,secondary:#ffffff"></lord-icon>
      </button>

      <!-- print btn  -->
      <button v-if="isPrintBtn && !isSearchBtn && !isSubmitBtn" @click="click" type="button" :id="printBtnId" :class="printBtnClass">
         <span>{{$t('lang_print')}}</span>
         <lord-icon  src="https://cdn.lordicon.com/isugonwi.json" trigger="hover" colors="primary:#ffffff"></lord-icon>
      </button>

      <!-- search btn  -->
      <button v-if="isSearchBtn && !isPrintBtn && !isSubmitBtn" @click="click" :id="searchBtnId" :class="searchBtnClass" tooltip :tt-text="$t('lang_search')" tt-dir="top">
         <lord-icon class="search" src="https://cdn.lordicon.com/osbjlbsb.json" colors="outline:#121331,secondary:#000000,secondary2:#4bb3fd,quaternary:#ebe6ef"  stroke="100" trigger="hover"></lord-icon>
      </button>
   </div>
</template>

<script>
   import functions   from '@/utils/functions';
   import {computed}  from 'vue';
   import { useI18n } from 'vue-i18n';

   export default {
      name: 'ConfirmBtn',

      props: {
         typeConfirm:                    Boolean,
         successMsgAfterConfirm:         Boolean,
         hideSuccessIconAfterConfirm:    Boolean,
         successMsgFontSizeAfterConfirm: String,
         btnTxt:                         String,
         btnIcon:                        String,
         btnIconFs:                      String,
         btnBgClr:                       String,
         btnSm:                          Boolean,
         btnCenter:                      Boolean,
         btnClass:                       String,
         title:                          String,
         text:                           String,
         icon:                           String,
         confirmBtnBgClr:                String,
         cancelBtnBgClr:                 String,
         confirmBtnTxt:                  String,
         cancelBtnTxt:                   String,
         confirmSuccessTxt:              String,
         confirmSuccessTime:             Number,
         confirmSuccessPos:              String,
         click: Function,
         isSearchBtn: {
            type: Boolean,
            default: false,
         },
         searchBtnClass: {
            type: String,
            default: 'align-self-end',
         },
         searchBtnId: {
            type: String,
            default: 'search_icon_btn',
         },
         isPrintBtn: {
            type: Boolean,
            default: false,
         },
         printBtnClass: {
            type: String,
            default: 'btn-color-gray-7-3d-x align-self-end',
         },
         printBtnId: {
            type: String,
            default: 'print_btn',
         },
         isSubmitBtn: {
            type: Boolean,
            default: false,
         },
         submitBtnClass: {
            type: String,
            default: 'btn btn-success flex-center align-self-end gap-2',
         },
         submitBtnId: {
            type: String,
            default: 'submit_btn',
         }
      },

      setup(props, context) {
         const {t} = useI18n();

         // show confirm modal to cancel or confirm 
         function showConfirmation() {
            if (!props.typeConfirm) return;

            let title  = props.title == undefined ? (props.icon == 'error' ? 'lang_you_sure_confirm_deleting' : 'lang_you_want_to_confirm_action') : props.title;
            let text   = props.text ?? '';
            let icon   = props.icon == undefined ? 'warning' : props.icon;

            let confirmButtonColor = props.confirmBtnBgClr    ?? (props.icon == 'success' ? '#333' : (props.icon == 'error' ? '#d33' : '#3085d6'));
            let cancelButtonColor  = props.cancelBtnBgClr     ?? (props.icon == 'error' ? '#3085d6' : '#d33');
            let confirmBtnText     = props.confirmBtnTxt      ?? 'lang_yes';
            let cancelBtnText      = props.cancelBtnTxt       ?? 'lang_no';
            let confirmSuccessTxt  = props.confirmSuccessTxt  ?? 'lang_deleted';
            let confirmSuccessTime = props.confirmSuccessTime ?? 1250;
            let confirmSuccessPos  = props.confirmSuccessPos  ?? 'top-end';
            
            let hideSuccessIconAfterConfirm = props.hideSuccessIconAfterConfirm ?? false;
            let successMsgFontSizeAfterConfirm  = props.successMsgFontSizeAfterConfirm ?? 'fs-25';
            let titleFontSize = window.innerWidth <= 767 ? 'fs-22' : '';

            Swal.fire({
               title: `<span class="txt-cap ${titleFontSize}">${t(title)}</span>`,
               text: t(text),
               icon,
               customClass: {
                  title: 'text-center border-0',
               },
               showCancelButton: true,
               confirmButtonColor,
               cancelButtonColor,
               confirmButtonText: `<span class="text-cap">${t(confirmBtnText)}</span>`,
               cancelButtonText: `<span class="txt-cap">${t(cancelBtnText)}</span>`
               
            }).then((result) => {
               let isConfirmed = false;
               
               if (result.isConfirmed) {
                  if (props.successMsgAfterConfirm ?? false) functions.success(confirmSuccessTxt, hideSuccessIconAfterConfirm, confirmSuccessTime, successMsgFontSizeAfterConfirm, confirmSuccessPos);
                  isConfirmed = true;
               } else isConfirmed = false;
               
               context.emit('make-confirmation', isConfirmed);
            });
         };

         // main btn text 
         const mainBtnTxt = computed(()=> {
            if (props.btnTxt == undefined) {
               if      (props.icon == 'error')   return '';
               else if (props.icon == 'success') return '';
               else if (props.icon == 'warning') return 'lang_confirm';
               else if (props.icon == 'info')    return 'lang_confirm';
               else return '';
            } else return props.btnTxt;
         });

         // main btn background color
         const mainBtnBg = computed(()=> {
            if (props.btnBgClr == undefined || props.btnBgClr == '') {
               if      (props.icon == 'error')   return 'btn-danger';
               else if (props.icon == 'success') return 'btn-success';
               else if (props.icon == 'warning') return 'btn-warning';
               else if (props.icon == 'info')    return 'btn-primary';
               else return 'btn-dark';
            }
            return props.btnBgClr != undefined ? props.btnBgClr : 'btn-primary'
         });

         // main btn icon
         const mainBtnIcon = computed(()=> {
            if (props.btnIcon == undefined || props.btnIcon == '') {
               if      (props.icon == 'error')   return '<i class="fa-solid fa-trash"></i>';
               else if (props.icon == 'success') return '<i class="fas fa-edit"></i>';
               else if (props.icon == 'warning') return '<i class="fa-solid fa-circle-exclamation"></i>';
               else if (props.icon == 'info')    return '<i class="fa-solid fa-circle-info"></i>';
               else return undefined
            }
            
            if (props.btnIcon.trim() == 'delete') return '<i class="fa-solid fa-trash"></i>'
            if (props.btnIcon.trim() == 'confirm') return '<i class="fa-solid fa-check"></i>'
            if (props.btnIcon.trim() == 'search') return '<i class="fa-solid fa-magnifying-glass"></i>'
            if (props.btnIcon.trim() == 'warning') return '<i class="fa-solid fa-circle-exclamation"></i>'
            
            return props.btnIcon;
         });
         
         return {
            mainBtnTxt,
            mainBtnBg,
            mainBtnIcon,

            showConfirmation
         }
      },
   }
</script>


<style scoped lang="scss">
@use '@sass/utils' as *;

#submit_btn {
   lord-icon {
      width: rem(30);
      height: rem(30);
      transform: rotateX(180deg);
   }
}

</style>