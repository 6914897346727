export default {
   // user data 
   intiUser(state, user = null) {
      state.user = user;
   },

   // set auth 
   setAuth(state, auth = false) {
      state.auth = auth;
      
      if (!auth) state.user = null;
      
   },
}