import getters   from './getters';
import mutations from './mutations';
import actions   from './actions';
import {CookiesController} from '@/utils/classes/CookiesController';

const CookiesClass = new CookiesController();

export default {
   namespaced : true,

   state() {
      return {
         language: CookiesClass.getCookie(LANG_COOKIE_NAME) || DEFAULT_LANG, 
      }
   },

   getters,
   mutations,
   actions,
};
