<template>
   <div class="page-loading" id="page-loading">
      <ul class="diamond">
         <li></li>
         <li></li>
         <li></li>
         <li></li>
         <li></li>
         <li></li>
         <li></li>
      </ul>
   </div>
</template>

<script>
export default {
   setup () {

      return {}
   }
}
</script>

<style lang="scss" scoped>
   @use "@sass/variables" as *;
   @use '@sass/utils' as *;

   .page-loading {
      --delay-increase: .1;
      --delay-transition: .1;
      --animation-transition: .2;

      --opacity-transition: 1.75;
      --opacity-delay-transition: 1;
      --opacity-delay-increase: .3;

      --li-one-bg: #7ad997;
      --li-two-bg: #64c592;
      --li-three-bg: #54b28d;
      --li-four-bg: #7ad997;
      --li-five-bg: #b4fea5;
      --li-six-bg: #90ef9d;
      --li-seven-bg: #c9feac;

      $border-width: 20;
      $distance: 59;

      position: fixed;
      inset: 0;
      z-index: 10000;
      display: grid;
      place-items: center;
      background-color: var(--dark-clr);
      transition: .3s ease-in-out;
      overflow: hidden;

      &.hide {
         pointer-events: none;
         opacity: 0;
         z-index: -1;
      }

      .diamond {
         position: absolute;
         inset: 0;
         width: rem(200);
         height: rem(200);
         display: block;
         margin: auto;

         li {
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            opacity: 0;
            z-index: 100;
            transition: .3s ease;
         }

         li:nth-child(1) {
            border-width: rem($border-width) rem($border-width) 0 rem($border-width);
            border-color: var(--li-one-bg) transparent transparent transparent;
            left: 0;
            right: 0;
            margin: auto;
            animation: traingle1 calc(var(--animation-transition) * 1s) calc(calc(var(--delay-transition) + calc(var(--delay-increase) * 6)) * 1s) ease, opacity calc(var(--opacity-transition) * 1s) calc(var(--opacity-delay-transition) * 1s) ease infinite;
            animation-fill-mode: forwards;
         }
      
         li:nth-child(2) {
            border-width: 0 rem($border-width) rem($border-width) rem($border-width);
            border-color: transparent transparent var(--li-two-bg);
            left: rem($distance);
            animation: traingle2 calc(var(--animation-transition) * 1s) calc(calc(var(--delay-transition) + calc(var(--delay-increase) * 5)) * 1s) ease, opacity calc(var(--opacity-transition) * 1s) calc(var(--opacity-delay-transition) * 1s) ease infinite;
            animation-fill-mode: forwards;
         }

         li:nth-child(3) {
            border-width: 0 rem($border-width) rem($border-width) rem($border-width);
            border-color: transparent transparent var(--li-three-bg);
            right: rem($distance);
            animation: traingle3 calc(var(--animation-transition) * 1s) calc(calc(var(--delay-transition) + calc(var(--delay-increase) * 4)) * 1s) ease, opacity calc(var(--opacity-transition) * 1s) calc(var(--opacity-delay-transition) * 1s) ease infinite;
            animation-fill-mode: forwards;
         }

         li:nth-child(4) {
            border-width: 0 rem($border-width) rem($border-width) rem($border-width);
            border-width: rem($border-width) rem($border-width) 0 rem($border-width);
            border-color: var(--li-four-bg) transparent transparent transparent;
            animation: traingle4 calc(var(--animation-transition) * 1s) calc(calc(var(--delay-transition) + calc(var(--delay-increase) * 3)) * 1s) ease, opacity calc(var(--opacity-transition) * 1s) calc(calc(var(--opacity-delay-transition) + var(--opacity-delay-increase)) * 1s) ease infinite;
            animation-fill-mode: forwards;
         }
      
         li:nth-child(5) {
            border-width: 0 rem($border-width) rem($border-width) rem($border-width);
            border-width: rem($border-width) rem($border-width) 0 rem($border-width);
            border-color: var(--li-five-bg) transparent transparent transparent;
            animation: traingle5 calc(var(--animation-transition) * 1s) calc(calc(var(--delay-transition) + calc(var(--delay-increase) * 2)) * 1s) ease, opacity calc(var(--opacity-transition) * 1s) calc(calc(var(--opacity-delay-transition) + var(--opacity-delay-increase)) * 1s) ease infinite;
            animation-fill-mode: forwards;
         }

         li:nth-child(6) {
            border-width: 0 rem($border-width) rem($border-width) rem($border-width);
            border-color: transparent transparent var(--li-six-bg);
            left: 0;
            right: 0;
            margin: auto;
            animation: traingle6 calc(var(--animation-transition) * 1s) calc(calc(var(--delay-transition) + var(--delay-increase)) * 1s) ease, opacity calc(var(--opacity-transition) * 1s) calc(calc(var(--opacity-delay-transition) + var(--opacity-delay-increase)) * 1s) ease infinite;
            animation-fill-mode: forwards;
         }

         li:nth-child(7) {
            border-width: rem($border-width) rem($border-width) 0 rem($border-width);
            border-color: var(--li-seven-bg) transparent transparent transparent;
            left: 0;
            right: 0;
            margin: auto;
            animation: traingle7 calc(var(--animation-transition) * 1s) calc(var(--delay-transition) * 1s) ease, opacity calc(var(--opacity-transition) * 1s) calc(calc(var(--opacity-delay-transition) + calc(var(--opacity-delay-increase) * 2)) * 1s) ease infinite;
            animation-fill-mode: forwards;
         }
      }
   }

</style>