<template>
   <from @submit.prevent="search" @keydown.enter="searching" id="global_search" class="global_search">
      <!-- :input="suggestions" -->
      <!-- :keyup="move" -->
      <search-input
         v-model="globalSearch"
         name="global_search" 
         id="global_search_input"
         placeholder=""
         
         label-btn-id="global_search_btn"
         :labelBtnClass="`${isMobile ? (globalSearch.trim() != '' ? '' : 'disable-btn') : ''}`"
         
         :has-extra-el="true"
         extra-el-class="show-placeholder"
         :extra-el-txt="`${$t('lang_item_name')} - ${$t('lang_barcode')} - ${$t('lang_product_code')} - ${$t('lang_invoice_number')}`"
      />

      <!-- search suggestion  -->
      <suggestions-comp suggestionId="show_global_search_suggestions" :suggestions="suggests" @suggestion-clicked="(value) => {globalSearch = value; search()} " />
   </from>
</template>

<script>
import functions            from '@/utils/functions';
import {useMoveSuggestions} from '@/utils/composable/useMoveSuggestions';
import {useShowSuggestions} from '@/utils/composable/useShowSuggestions';
import SuggestionsComp      from '@/components/global/SuggestionsComp.vue'
import router               from '@/router';
import store                from '@/store/store';
import { toRefs, reactive, onMounted, inject }   from 'vue';

export default {
   components: {
      SuggestionsComp,
   },

   setup () {
      const state = reactive({
         globalSearch: '',
         isMobile: false,
         suggests: [],
      });
      
      // ===== Class
      const Classes      = inject('Classes');
      const CRUDManager  = Classes.getClass('CRUDManager');
      const DOMModifier  = Classes.getClass('DOMModifier');


      async function search() {
         return;
         
         let value = state.globalSearch.trim();
         
         if (isEmpty(value)) {
            functions.warning({msg: 'lang_plz_write_search_info', toast: true});
            return;
         }
         
         let body = { search: state.globalSearch };
         let {data} = await CRUDManager.get('global_search', body);

         if (isEmpty(data.report)) {
            functions.warning({msg: 'lang_no_data_for_search_result', toast: true, time: 2000});
            return;
         }

         if (data.isMultiResults) {
            functions.confirmedAction('lang_data_same_results_looking_for_invoice').then(resp => {
               if (resp) {
                  if (data.type == 'invoice') router.replace(`/sales/invoice_reports/${data.report.invoice.IId}/${data.report.invoice.ICurrencyType}`);
                  
               } else router.replace(`/product_page/${data.report.product.PId}`);
            })
         } else {
            if (data.type == 'invoice') router.replace(`/sales/invoice_reports/${data.report.IId}/${data.report.ICurrencyType}`);
            else if (data.type == 'product') router.replace(`/product_page/${data.report.PId}`);
         }

         state.globalSearch = '';
         document.querySelector('#global_search_input').blur();
      }

      // ~~~~~~~~~~~~~~~~~~~~~~~~~📦 product search suggestions 📦~~~~~~~~~~~~~~~~~~~
      function searching(e) {
         e.preventDefault();

         if (e.keyCode == 13 && isEmpty(document.querySelector('#global_search #show_global_search_suggestions').innerHTML.trim())) search();
      }

      // move between items suggestions with up & down keys
      function move(event) {
         let value = useMoveSuggestions({
            input:       '#global_search_input',
            suggestions: '#show_global_search_suggestions',
            event,
         });

         
         if (isEmpty(value)) return;

         state.globalSearch = value;
         state.suggests     = [];

         search();
      }
      
      async function suggestions(event) {
         let data = await useShowSuggestions({
            url: 'global_controller/show_items_suggestions',
            event,
         });

         state.suggests = data;
      }

      onMounted(()=> {
         document.addEventListener('click', function(e) {
            if (DOMModifier.findParent({element: e.target, parentSelector: '#show_global_search_suggestions'})) return;
            state.suggests = [];
         });

         setMainHeadingPadding();
      });
      

      function checkMobileSize() {
         if (window.innerWidth >= 768) state.isMobile = false;
         else state.isMobile = true;
      }
      checkMobileSize();

      function setMainHeadingPadding() {
         let global_search_form  = document.querySelector('#global_search');

         if (global_search_form) {
            let global_search_width =  global_search_form.getBoundingClientRect().width;
            document.body.style.setProperty('--global_search_width', global_search_width + 'px');
         }

      }

      window.addEventListener('resize', ()=> {
         setMainHeadingPadding();
         checkMobileSize();
      });

      return {
         ...toRefs(state),

         search,
         searching,
         suggestions,
         move,
      }
   }
}
</script>

<style lang="scss">
@use '@sass/variables' as *;
@use '@sass/utils' as *;

   .global_search {
      margin-right: auto;

      @include br(sm, true) {
         margin-right: 0;
         position: relative;

         input::placeholder {
            font-size: rem(13);
         }
      }

      #global_search_btn {
         
      }
      
      .input-wrapper {
         position: relative;
         overflow: hidden;

         #global_search_input:not(:placeholder-shown) + span,
         #global_search_input:focus + span { display: none }

         .show-placeholder {
            position: absolute;
            left: 0;
            top: 50%;
            z-index: 10;
            display: flex;
            width: max-content;
            pointer-events: none;
            transform: translate(100%, -50%);
            animation: show-news 8s linear infinite reverse;
            color: $color-gray-8;
            
            @include br(sm, true) { 
               animation: show-news 15s linear infinite reverse 
            }
         }

         #global_search_input::placeholder { opacity: 0} 
      }
   }

</style>