export const expenses = [
   {
      path: '/expenses_types',
      name: 'expenses_types',
      component: () => import('../views/pages/ExpensesTypes.vue'),
      meta: {
         pageTitle: 'lang_expenses_types',
      }
   },
   {
      path: '/expenses',
      name: 'expenses',
      component: () => import('../views/pages/Expenses.vue'),
      meta: {
         pageTitle: 'lang_add_expenses',
      }
   },
   {
      path: '/expenses_reports',
      name: 'expenses_reports',
      component: () => import('../views/pages/ExpensesReports.vue'),
      meta: {
         pageTitle: 'lang_expenses_reports',
      }
   },
   {
      path: '/print_expenses_reports',
      name: 'print_expenses_reports',
      component: () => import('../views/print/PrintExpensesReports.vue'),
      props: route => ({
         page: route.query.page,
         currency: route.query.currency,
         date_from: route.query.date_from,
         date_to: route.query.date_to,
      }),
   },
];