<template>
   <div class="table-responsive">
      <table :class="[tableClass, 'table', verticalTable ? 'table-vertical' : '']" v-table>
         <thead :class="headClass">
            <tr>
               <th v-if="selectDelete">
                  <div class="select-all">
                     <label for="chose-all"> {{ $t('lang_pick_all') }} </label>
                     <input class="form-check-input" type="checkbox" id="chose-all" v-select-all>
                  </div>
               </th>
               <slot name="head"></slot>
            </tr>
            <slot name="head-new-tr"></slot>
            <slot v-if="verticalTable" name="body"></slot>
         </thead>
         <tbody v-if="!verticalTable">
            <slot name="body"></slot>
         </tbody>
         <tfoot>
            <slot name="footer"></slot>
         </tfoot>
      </table>
   </div>
</template>

<script>
export default {
   name:'Tables',
   props: {
      selectDelete:  { type: Boolean, default: false},
      tableClass:    { type: String, default: 'table'},
      headClass:     { type: String, default: null },
      verticalTable: { type: Boolean, default: false },
   },
}
</script>