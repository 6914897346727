import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import {CookiesController} from '@/utils/classes/CookiesController';

const CookiesClass = new CookiesController();

export default {
   namespaced : true,

   state() {
      return {
         user: null,
         auth: CookiesClass.getCookie(JWT_COOKIE_NAME) ? true : false,
      }
   },

   getters,
   mutations,
   actions,
};