<template>
   <div id="header_1" class="header_1">
      <div class="container">
         <main-heading>{{ $t($route.meta.pageTitle || 'error') }}</main-heading>

         <global-search />

         <div class="header-btns">
            <router-link 
               v-if="$store.getters['permissions/getPermissions'].settings === ACTIVE_IN_DB" 
               to="/settings" 
               id="fixed_settings_btn" 
               class="header-btn"
               tooltip 
               :tt-dir="$isArb() ? 'right' : 'left'" 
               :tt-text="$t('lang_program_settings')" 
               tt-fw-bold 
            >
               <i class="fa-solid fa-gear"></i>
            </router-link>
   
            <router-link 
               to="/notifications" 
               id="fixed_notification_btn" 
               class="header-btn"
               tooltip 
               :tt-dir="$isArb() ? 'right' : 'left'" 
               :tt-text="$t('lang_notifications')" 
               tt-fw-bold
            >
               <i class="fa-solid fa-bell"></i>
               <span></span>
            </router-link>
            
            <button 
               type="button" 
               @click="previousPage"  
               id="prv_page_btn" 
               class="header-btn"
               tooltip :tt-dir="$isArb() ? 'right' : 'left'" 
               tt-fw-bold 
               :tt-text="$t('lang_previous_page')"
            >
               <i class="fa-solid fa-left-long"></i>
            </button>
         </div>
      </div>
   </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import router               from '@/router';

import MainHeading   from '@/components/global/MainHeading.vue';
import GlobalSearch  from '@/components/global/GlobalSearch.vue';

export default {

   components: {
      MainHeading,
      GlobalSearch,
   },

   setup () {
      const state = reactive({
         ACTIVE_IN_DB,
         NOT_ACTIVE_IN_DB,
      });

      function previousPage() {
         router.go(-1);
      }
   
      return {
         ...toRefs(state),

         previousPage,
      }
   },
}
</script>

<style lang="scss" scoped>
@use '@sass/utils' as *;
@use '@sass/variables' as *;

.header_1 {
   position: relative;
   margin-block: rem(10);

   &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: rem(1);
      background-image: linear-gradient(to right, transparent, $color-gray-7, transparent);
      bottom: rem(-10);
      left: 0;
   }

   > * {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      row-gap: rem(5);
      column-gap: rem(20);
   }

   .header-btns {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: rem(5);
      font-size: rem(18);
      
      .header-btn {
         padding: rem(2) rem(7);
         border: solid rem(1) $color-gray-5;
         border-radius: rem(5);
         color: $color-gray-10;
      }
   }
}

</style>