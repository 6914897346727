import {Classes} from '@/utils/classes/Classes';

const CRUDManager = Classes.getClass('CRUDManager');
CRUDManager.showDataLoader = false;

/*
   the function to get the suggestions and show it

   options = {
      url         => the api url u will get the suggestions
      input       => input element selector
      suggestions =>  suggestions element selector
      event       => input event
   }
*/

export async function useShowSuggestions (options = {}) {
   if (options.event.keyCode == 40 || options.event.keyCode == 38 || options.event.keyCode == 13 ) return [];

   let body   = { search: options.event.target.value ?? ''}
   let {data} = await CRUDManager.get(options.url ?? '', body);

   return data;
}