<template>
   <div class="print_header_design_3" id="print_header_design_3">
      <div class="info">
         <div class="logo">
            <h2 class="title">{{ shopName }}</h2>
            <img :src="`${URL_PATH + UPLOADS_IMG_FOLDER_NAME}shop/${shopImg}`" class="rounded-img-75 no-img-loader" alt="Shop Logo">
         </div>
         <p class="desc">{{ titleMsg  }}</p>
      </div>

      <div class="info">
         <div>
            <!-- date  -->
            <div class="info-label">
               <span class="label" >{{$t('lang_date')}}</span>
               <span>{{ date }}</span>
            </div>
            <!-- time  -->
            <div class="info-label">
               <span class="label" >{{$t('lang_time')}}</span>
               <span>{{ time }}</span>
            </div>
            <!-- invoice  -->
            <div v-if="invoiceNumber" class="invoice_number info-label">
               <span class="label">{{ $t('lang_invoice_number') }}</span>
               <span>{{ invoiceNumber }}</span>
            </div>
            <!-- currency  -->
            <div v-if="invoiceNumber" class="invoice_currency info-label">
               <span class="label">{{ $t('lang_invoice') }}</span>
               <span>{{ $t(currencyName) }}</span>
            </div>
         </div>

         <div>
            <!-- phone  -->
            <div v-if="shopPhone" class="contact">
               <i class="fa-solid fa-phone"></i>
               <span>{{ shopPhone  }}</span>
            </div>
            <!-- address  -->
            <div v-if="shopAddress" class="contact">
               <i class="fa-solid fa-location-dot"></i>
               <span>{{ shopAddress }}</span>
            </div>
            <!-- secondary address  -->
            <div v-if="shopSecondAddress" class="contact">
               <i class="fa-solid fa-location-dot"></i>
               <span>{{ shopSecondAddress }}</span>
            </div>
            <div class="social" v-if="shopFacebook || shopInstagram || shopTelegram || shopWhatsapp">
               <div class="social_row">
                  <div v-if="shopFacebook">
                     <i class="fa-brands fa-facebook"></i>
                     <span>{{ shopFacebook }}</span>
                  </div>
                  <div v-if="shopInstagram">
                     <i class="fa-brands fa-instagram"></i>
                     <span>{{ shopInstagram }}</span>
                  </div>
               </div>
               <div class="social_row">
                  <div v-if="shopWhatsapp">
                     <i class="fa-brands fa-whatsapp"></i>
                     <span>{{ shopWhatsapp }}</span>
                  </div>
                  <div v-if="shopTelegram">
                     <i class="fa-brands fa-telegram"></i>
                     <span>{{ shopTelegram }}</span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { reactive, toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n';

export default {
   props: {
      currency: String,
      invoiceNumber: {type: [String, Boolean],default: false},
      title:         {type: [String, Boolean],default: false},
      date:          {type: String,default: getDate()},
      time:          {type: String,default: getTime()}
   },
   setup (props) {
      const store = useStore();
      const { t } = useI18n();
      const shop  = store.getters['shop/getShop'];

      const state = reactive({
         shopName: shop.name || false,
         shopImg: shop.image || false,
         shopPhone: shop.phone || false,
         shopAddress: shop.address || false,
         shopSecondAddress: shop.secondAddress || false,
         shopFacebook: shop.facebookLink || false,
         shopInstagram: shop.instagramLink || false,
         shopTelegram: shop.telegramLink || false,
         shopWhatsapp: shop.whatsappLink || false,

         URL_PATH,
         UPLOADS_IMG_FOLDER_NAME,
      });

      const currencyName = computed(()=> {
         if (props.currency == 'dollar') return 'lang_in_dollar';
         else return 'lang_in_dinar';
      });

      const titleMsg = computed(()=> {
         if (props.title == false) return shop.description;
         else return t(props.title);
      });
   
      return {
         ...toRefs(state),

         currencyName,
         titleMsg,
      }
   }
}
</script>


<style lang="scss" scoped>
@use '@sass/utils' as *;

.print_header_design_3 {
   display: grid;
   gap: rem(10);
   margin-bottom: rem(20);
   min-height: rem(150);
   height: fit-content;

   .info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > * {
         display: grid;
         gap: rem(8);
      }
   }

   .desc {
      padding: rem(20) rem(30);
      text-transform: capitalize;
      color: red;
   }

   .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: rem(20);
   }

   .invoice_currency span:not(.label),
   .invoice_number span:not(.label) { 
      font-weight: bolder;
      color: var(--print-d3-clr) 
   }

   
   .info-label {
      margin-bottom: rem(5);
      span {
         text-transform: capitalize;
      }
      .label {
         color: var(--print-d3-clr);
      }
   }

   .contact ,
   .social {
      span { color: var(--print-d3-clr) }
   }
}
</style>