import {CookiesController} from '@/utils/classes/CookiesController';

const CookiesClass = new CookiesController();


export class CRUDManager {
   #headers     = {'Content-Type' : 'application/x-www-form-urlencoded'};
   #apiURL      = API_URL;
   #method      = 'POST';

   #checkToken  = true;
   #hasLoader   = true;

   #defaultBody = {};
   
   async get(url, body = this.#defaultBody) {
      
      let {[RESPONSE_DATA_NAME]: response} = await this.#shortFetch(url, body);

      log('get', response);

      if (typeof response == 'string') return {[RESPONSE_DATA_NAME]: response, [RESPONSE_BOOLEAN_NAME]: response[RESPONSE_BOOLEAN_NAME]};
      else if (typeof response == 'number') return {[RESPONSE_DATA_NAME]: response, [RESPONSE_BOOLEAN_NAME]: response[RESPONSE_BOOLEAN_NAME]};
      else if (isEmpty(response[RESPONSE_DATA_NAME])) return {[RESPONSE_DATA_NAME]: [], [RESPONSE_DATA_LENGTH_NAME]: 0, [RESPONSE_BOOLEAN_NAME]: false};

      return {
         [RESPONSE_DATA_NAME]: response[RESPONSE_DATA_NAME], 
         [RESPONSE_DATA_LENGTH_NAME]: response[RESPONSE_DATA_LENGTH_NAME], 
         [RESPONSE_BOOLEAN_NAME]: response[RESPONSE_BOOLEAN_NAME]
      };
   }

   async insert(url, body = this.#defaultBody) {
      let {[RESPONSE_DATA_NAME]: response} = await this.#shortFetch(url, body);

      log('insert', response);

      return {
         [RESPONSE_BOOLEAN_NAME]: response[RESPONSE_BOOLEAN_NAME], 
         [RESPONSE_DATA_NAME]: response[RESPONSE_DATA_NAME]
      };
   }

   async delete(url, body = this.#defaultBody) {
      let {[RESPONSE_DATA_NAME]: response} = await this.#shortFetch(url, body);
      
      log('delete', response);
      
      return {
         [RESPONSE_DATA_NAME]: response[RESPONSE_DATA_NAME], 
         [RESPONSE_BOOLEAN_NAME]: response[RESPONSE_BOOLEAN_NAME]
      };
   }
   
   async update(url, body = this.#defaultBody) {
      let {[RESPONSE_DATA_NAME]: response} = await this.#shortFetch(url, body);
      
      log('update', response);
      
      return {
         [RESPONSE_DATA_NAME]: response[RESPONSE_DATA_NAME], 
         [RESPONSE_BOOLEAN_NAME]: response[RESPONSE_BOOLEAN_NAME]
      };
   }

   async #shortFetch(url, data = null) {
      data = this.#prepareRequestBody(data);

      if (this.#hasLoader) {
         if (document.getElementById('loading-response')) 
            document.getElementById('loading-response').classList.remove('d-none');
      }
   
      if (this.#checkToken) this.#headers[HEADER_AUTH_NAME] =  `Bearer  ${CookiesClass.getCookie(JWT_COOKIE_NAME)}`;
      try {

         let response = await axios({
            method: this.#method,
            url,
            data,
            withCredentials: true,
            baseURL: this.#apiURL,
            headers: this.#headers,
         });
   
         return response;
   
      } catch (error) {
         console.error(new Error(error));
   
      } finally {
         if (this.#hasLoader) {
            if (document.getElementById('loading-response')) 
               document.getElementById('loading-response').classList.add('d-none');
         }
      }
   }

   #prepareRequestBody(body) {
      if (body === null || body === undefined) return body; 
      if (this.#method != 'POST') return body;

      let formData = new FormData();
      body[RESPONSE_POST_CHECKING_NAME] = 'submitted';
      for (const key in body) formData.append(key , body[key]);
   
      return formData;
   }

   setHeaders(header) {
      if (typeof header !== 'object') return;
      if (typeof header === null || Array.isArray(header)) return;
      if (isEmpty(header)) return;

      this.#headers = header;
   }

   set checkingForToken(bool) {
      if (typeof bool !== 'boolean') return;
      if (isEmpty(bool)) return;

      this.#checkToken = bool;
   }

   set showDataLoader(bool) {
      if (typeof bool !== 'boolean') return;
      if (isEmpty(bool)) return;

      this.#hasLoader = bool;
   }

   set formMethod(methodType) {
      if (typeof methodType !== 'string') return;
      if (isEmpty(methodType)) return;

      this.#hasLoader = bool;
   }
}