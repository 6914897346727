import {Classes} from '@/utils/classes/Classes';

const CRUDManager = Classes.getClass('CRUDManager');
CRUDManager.showDataLoader = false;

export default {
   // user data 
   async setUser(context) {
      let {[RESPONSE_DATA_NAME]: data, [RESPONSE_BOOLEAN_NAME]: isSuccess} = await CRUDManager.get('users_fetcher/get_modified_user_data');
      if (isSuccess) context.commit('intiUser', data);
   },
   
   async updateUser(context, userData = {}) {
      let {[RESPONSE_BOOLEAN_NAME]: isUpdated} = await CRUDManager.update('users_modifier/update_user', userData);
      if (isUpdated) context.dispatch('setUser');
   },


   // auth changing
   changAuth(context, isLogged = false) {
      context.commit('setAuth', isLogged);
   },
}