export const users = [
   {
      path: '/branches',
      name: 'branches',
      component: () => import('../views/pages/Branches.vue'),
      meta: {
         pageTitle: 'lang_branches',
      }
   },

   {
      path: '/users',
      name: 'users',
      component: () => import('../views/pages/Users.vue'),
      meta: {
         pageTitle: 'lang_users',
      }
   },

   {
      path: '/users_permissions',
      name: 'users_permissions',
      component: () => import('../views/pages/UsersPermissions.vue'),
      meta: {
         pageTitle: 'lang_users_permissions',
      }
   },

   {
      path: '/settings',
      name: 'settings',
      component: () => import('../views/pages/Settings.vue'),
      meta: {
         pageTitle: 'lang_program_settings',
      }
   },

   {
      path: '/shop_data',
      name: 'shop_data',
      component: () => import('../views/pages/ShopData.vue'),
      meta: {
         pageTitle: 'lang_add_shop_data',
      }
   },
];