<template>
   
   <Sidebar v-if="showNav && show"/>
   <page-header v-if="showHeader && show"/>

   <!-- main content  -->
   <template v-if="!authChecking">
      <router-view v-if="show" v-slot="{ Component }" v-global-js>
         <transition mode="out-in">
            <component :is="Component"/>
         </transition>
      </router-view>
   </template>

   <div class="tooltip d-none" data-tooltip ></div>
   <page-loader />
   <data-loader />
</template>


<script>
   import {onMounted, inject, toRefs, reactive, watch} from 'vue';

   import router           from '@/router/index';
   import store            from '@/store/store';
   import functions        from '@/utils/functions/index';
   import DataLoader       from './components/global/DataLoader.vue';
   import Sidebar          from './components/template/Sidebar.vue';
   import GlobalSearch     from './components/global/GlobalSearch.vue';
   import PageHeader       from './components/headers/Header_1.vue';
   import PageLoader       from './components/global/PageLoader.vue';
   import FixedBtns        from './components/global/FixedBtns.vue';

   
   export default {
      name: 'app',
      components: {
         DataLoader,
         Sidebar,
         GlobalSearch,
         PageLoader,
         FixedBtns,
         PageHeader,
      },

      setup() {
         const state = reactive({
            showNav: true,
            showHeader: true,
            showGlobalSearch: true,
            fixedBtns: true,
            show: false,
            authChecking: true,
            loginChecked: false,
         });
         
         const Classes     = inject('Classes');
         const CRUDManager = Classes.getClass('CRUDManager');
         const NotificationsChecker = Classes.getClass('NotificationsChecker');
         const CookiesController    = Classes.getClass('CookiesController');

         // set language in cookie if not exists 
         function setLangCookie() {
            if (CookiesController.getCookie(LANG_COOKIE_NAME) !== null) return;
            CookiesController.setCookie(LANG_COOKIE_NAME, DEFAULT_LANG, 1000);
         };

         // set the styles of language which is ltr or rtl 
         function setLanguageStyle() {
            let lang      = CookiesController.getCookie(LANG_COOKIE_NAME);
            let link      = document.head.querySelector('[data-sub-css]');
            let linkBsLtr = document.head.querySelector('[data-bootstrap-ltr]');
            let linkBsRtl = document.head.querySelector('[data-bootstrap-rtl]');

            if (lang == 'en') link.href = `${CSS_LTR_FILE_PATH}?modified=${new Date().getTime()}`;
            else link.href = `${CSS_RTL_FILE_PATH}?modified=${new Date().getTime()}`;
            
            // for bootstrap direction 
            if (lang == 'en') {
               linkBsLtr.href = BS_CDN_LTR_CSS;
               linkBsRtl.href = '';
               
            } else {
               linkBsRtl.href = BS_CDN_RTL_CSS;
               linkBsLtr.href = '';
            } 
         };

         // stop css browser caching 
         function stopCssCaching() {
            let link  = document.querySelector('[data-main-css]');
            let href  = link.getAttribute('href');
            link.href = href + '?modified=' + new Date().getTime();
         };

         router.beforeResolve((to, from)=> {
            if (CookiesController.getCookie(JWT_COOKIE_NAME) === null) store.dispatch('user/changAuth', false); 

            let isLoggedIn = store.getters['user/getAuth'];
            let needAuth   = to.meta.auth ?? true;

            if (!isLoggedIn && needAuth) router.push('/login');
            else if (isLoggedIn && !needAuth) router.push('/');
         })

         // make sure user is logged in if not .. show login page 
         function handleRouter() {
            state.show = true;
            state.authChecking = false;
            
            document.querySelector('#page-loading').classList.add('hide');
            document.body.style.overflow = 'auto';

            router.beforeEach((to, from, next) => {
               if (!store.getters['user/getAuth']) {
                  
                  if (to.meta.auth ?? true) 
                     next('/login');
                  else 
                     next();
                  
               } else {

                  if (!(to.meta.auth ?? true)) 
                     next('/');
                  else 
                     next();
               }
            });
         }

         async function isLogged() {
            onMounted(()=> document.body.style.overflow = 'hidden');
            state.loginChecked = true;
            
            CRUDManager.checkingForToken = true;
            CRUDManager.showDataLoader   = false;
            let {[RESPONSE_DATA_NAME]: data} = await CRUDManager.get('logged');
            

            if (data[RESPONSE_BOOLEAN_NAME]) {

               await store.dispatch('user/changAuth', true);
               await store.dispatch('user/setUser');
               await store.dispatch('settings/setSettings');
               await store.dispatch('permissions/setPermissions');
               await store.dispatch('shop/setShop');

               if (data[RESPONSE_DATA_NAME] == 'notification_on') {
                  await store.dispatch('notifications/makeNotification', true);
                  await store.dispatch('notifications/makeNotificationSound', 'on');
                  
                  NotificationsChecker.hasNotification();
               } else {
                  await store.dispatch('notifications/makeNotification', false);
                  await store.dispatch('notifications/makeNotificationSound', 'off');
                  
                  NotificationsChecker.hasNotification();
               }

            } else {
               await store.dispatch('user/changAuth', false);
               router.push('/login');
            }

            handleRouter();
         }

         onMounted(()=> {
            document.querySelector('html head title').innerHTML = PROJECT_TITLE;
         });
         
         return {
            ...toRefs(state),

            isLogged,
            
            setLangCookie,
            setLanguageStyle,
            stopCssCaching,
         }
      },

      created() {
         this.setLangCookie();
         this.stopCssCaching();
         this.setLanguageStyle();

         this.$watch(() => this.$store.state.config.language, () => this.setLanguageStyle(), {immediate: true});
         this.$watch(() => this.$route, () => {
            this.showNav          = this.$route.meta.showNav ?? true
            this.showHeader       = this.$route.meta.showHeader ?? true
            this.showGlobalSearch = this.$route.meta.showGlobalSearch ?? true
            this.fixedBtns        = this.$route.meta.fixedBtns ?? true

            if (!this.showNav) document.body.classList.add('px-0');
            else document.body.classList.remove('px-0');

            if (!this.loginChecked) this.isLogged();
         });

      },
   }
</script>

<style lang="scss">
   .v-leave-to,
   .v-enter-from {
      opacity: 0;
      filter: blur(5px);
   }
   
   .v-enter-active,
   .v-leave-active {
      transition: .03s ease;
   }

   // for table 
   .table-transition-enter-from,
   .table-transition-leave-to {
      opacity: 0;
      filter: blur(5px);
   }

   .table-transition-enter-active, 
   .table-transition-leave-active {
      transition: .2s;
   }

   // for sliding 
   .slideUp-transition-enter-from,
   .slideUp-transition-leave-to {
      opacity: 0;
      // filter: blur(5px);
      // max-height: 0;
   }

   .slideUp-transition-enter-active, 
   .slideUp-transition-leave-active {
      transition: .4s;
      // max-height: 1000px;

   }
</style>
